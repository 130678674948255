import { I18N } from '@/app'
import { ScoresContext } from './context'
import { Button, SegmentedControl, View } from '..'

export const TabController = () => {
  const { tab, setTab } = ScoresContext.useTabs()

  return <SegmentedControl
    variants={['fullWidth', 'noWrap']}
    touchableProps={{ tabIndex: 0 }}
    options={ScoresContext.tabs}
    value={tab}
    onValueChange={setTab}
  />
}
