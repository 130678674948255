import { APIClient } from '@/services'
import { useLocation } from '@reach/router'
import { useReadSearchParams } from './useReadSearchParams'

export function useSearchedIndustry() {

  const industryParam = useReadSearchParams().industry

  const industry = APIClient.Organisations.useIndustry(industryParam)

  return industry

}
