import { AsyncReturnType, PaginationResponse, QueryManager, ReactQuery, TypeGuards, useState, waitFor } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api, I18N } from '@/app'
import { CategoriesResponse, CategoryDict, Organisation, OrganisationCategory } from '@/types'
import { usePageContext } from '@/utils'
import { analytics } from '../analytics'

const BASE_URL = 'organizations/'

type OrganizationFilters = {
  name?: string
  show_unverified?: boolean
  sort_by?: 'name' | '-min_employees' | '-max_employees'
}

async function getUnknownOrganisation() {
  const response = await api.get<Organisation>(`${BASE_URL}unknown/`)

  return response.data
}

async function getOrganisationCategories() {
  const categoryResponse = await api.get<CategoriesResponse>(`questions/categories/`)
  const subCategoryResponse = await api.get<CategoryDict>(`questions/subcategories/`)

  const data = {
    categories: categoryResponse.data,
    subcategories: subCategoryResponse.data,
  }

  return data
}

export const organisationQueryKeys = {
  unknownOrganisation: ['unknown-organisation'],
  categories: ['categories'],
}

export const organisationsManager = new QueryManager({
  itemType: {} as Organisation,
  name: 'organisations',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: OrganizationFilters) => {

    const response = await api.get<PaginationResponse<Organisation>>(BASE_URL, {
      params: {
        limit,
        offset,
        include_score: true,
        ...filters,
        name: filters.name?.trim() || undefined,
      },
    })

    return response.data
  },

  createItem: async (data) => {
    const response = await api.post(BASE_URL, data)

    return response.data
  },

  retrieveItem: async (id) => {
    if (TypeGuards.isNil(id)) return undefined
    const response = await api.get<Organisation>(`${BASE_URL}${id}/`, {
      params: {
        include_score: true,
      },
    })

    return response.data
  },

})

export const useUnknownOrganisation = () => {
  const query = ReactQuery.useQuery({
    queryKey: organisationQueryKeys.unknownOrganisation,
    queryFn: getUnknownOrganisation,
    onSuccess: (data) => {
      organisationsManager.updateItems(data)
    },
  })

  return {
    query,
    organisation: query.data,
  }
}

export const useCategories = () => {

  const ctxValue = usePageContext()?.categories

  const query = ReactQuery.useQuery({
    queryKey: organisationQueryKeys.categories,
    queryFn: () => {
      return getOrganisationCategories()
    },
  })

  const data: AsyncReturnType<typeof getOrganisationCategories> = query.data ?? ctxValue

  return {
    query,
    categories: data?.categories,
    subcategories: data?.subcategories,
  }
}

export function getOrganisationInvolved() {
  return api.get(`${BASE_URL}get_involved/`)
}

export async function contact(organisationId: Organisation['id']) {

  const response = await api.post(`${BASE_URL}contact/`, {
    organization: organisationId,
  })

  return response.data
}

export function useContact(organisation: Organisation) {
  const mut = ReactQuery.useMutation({
    mutationFn: contact,

  })

  const onContact = async () => {
    analytics.track('Info_requested', {
      organization: organisation?.name,
    })
    mut.mutateAsync(organisation.id)

  }

  return {
    contact: onContact,
    isSuccess: mut.isSuccess,
    isLoading: mut.isLoading,
  }
}

export async function listIndustries() {
  const response = await api.get<PaginationResponse<OrganisationCategory>>(`${BASE_URL}categories/`)

  return response.data
}

const industryQueries = {
  list: queryClient.queryKey<PaginationResponse<OrganisationCategory>>(['industries']),
  retrieve: queryClient.dynamicQueryKey<OrganisationCategory>((slugOrId: OrganisationCategory['slug' | 'id']) => ['industries', slugOrId]),
}
export function useIndustryOptions() {
  const query = ReactQuery.useQuery({
    queryKey: industryQueries.list.key,
    queryFn: listIndustries,
    select(data) {
      return data.results.map(i => {
        return {
          label: i.label,
          value: i.slug,
        }
      })
    },
  })

  return {
    query,
    industries: query.data,
  }
}

const getIndustry = async (slugOrId?: OrganisationCategory['slug' | 'id']) => {
  const response = await api.get<OrganisationCategory>(`${BASE_URL}categories/${slugOrId}/`)

  return response.data
}

export const useIndustry = (slugOrId?: OrganisationCategory['slug' | 'id']) => {
  const query = ReactQuery.useQuery({
    queryKey: industryQueries.retrieve.key(slugOrId),
    queryFn: () => getIndustry(slugOrId),
    initialData: () => {
      const data = industryQueries.list.getData()

      return data?.results?.find?.(i => i.slug === slugOrId)
    },
  })

  return {
    query,
    industry: query.data,
  }
}

type GetScoresParams = {
  category?: string
}

type GetScoresResponse = {
  score: Organisation['score']
  n_orgs: number
  category?: OrganisationCategory
}
export async function getScores(params?: GetScoresParams) {
  const response = await api.get<GetScoresResponse>(`${BASE_URL}scores/`, {
    params,
  })

  return response.data
}

export function useScores(params: GetScoresParams) {
  const query = ReactQuery.useQuery({
    queryKey: ['scores', params],
    queryFn: () => getScores(params),
  })

  return {
    query,
    ...query.data,
  }
}
