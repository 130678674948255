import React from 'react'
import { APIClient } from '@/services'
import { ClimateEngagementScore, ScoreHeader, ScoreModal } from '../Organisations'
import { ScoresContext } from './context'
import { OrgReviewButtons } from '../Organisation'
import { Text, View, ActionIcon } from '..'
import { useAppI18N } from '@/app'
import { useModal } from '@codeleap/common'

export const IndustryScoreOverview = () => {
  const { industry } = ScoresContext.useIndustrySelector()

  const profileIndustry = ScoresContext.useScoresContext(c => c.profile?.data?.organization?.category?.slug)

  const orgScores = APIClient.Organisations.useScores({
    category: industry,
  })

  const { t } = useAppI18N()

  const scoreModal = useModal(false)

  return <>
    <View variants={['column', 'gap:3', 'alignCenter', 'fullWidth']}>
      <ScoreHeader onExpand={scoreModal.toggle} />
      <ClimateEngagementScore
        score={orgScores.score}
        variant='modal'
        showReviews={false}
        loading={orgScores.query.isLoading}
      />
      <OrgReviewButtons isMyOrganisation={profileIndustry === industry}/>
    </View>
    <ScoreModal visible={scoreModal.visible} toggle={scoreModal.toggle} score={orgScores.score} />
  </>
}
