import React from 'react'
import { Page, QuizChoice } from '@/types'
import { LessonViewContent, ModalLessonAnswer, LoadingScreen, LearnOption, Image, View, LessonViewNext, PlayerFullscreen, LearnStore, useLessonContext } from '@/components'
import { IsDevEnv, variantProvider } from '@/app'
import { AnyFunction, TypeGuards, useState, useRef } from '@codeleap/common'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { useIsMobile, usePress, useElementScroll, useModal } from '@/utils'
import { analytics } from '@/services/analytics'

type LessonPageProps = {
  page: Page
  goToNextPage: AnyFunction
  goToPreviousPage: AnyFunction
  visible: boolean
  isChapterDone?: boolean
  index?: number
  children?: React.ReactNode
  currentPage?: number
}

const DEBUG = IsDevEnv && false
const VIEWED_PREVIOUS_PAGES = 3
const VIEWED_NEXT_PAGES = 5

export const LessonPage = (props: LessonPageProps) => {
  const {
    page,
    goToNextPage,
    goToPreviousPage,
    visible,
    isChapterDone,
    index,
    children,
    currentPage,
  } = props
  const prevAnswers = useRef([])
  const { inSwipe } = useLessonContext()

  const question = APIClient.Learn.useQuizQuestion({ questionId: page?.question })
  const isNeutral = question?.is_neutral

  const [selectedChoice, setSelectedChoice] = LearnStore(state => ([state?.choices?.[question?.id], state?.setChoice]))

  const answerModal = useModal()

  const [fullscreen, setFullscreen] = useState(false)

  const isMobile = useIsMobile()

  const hasQuestionAndNotSelectedChoice = (!!question?.id && !!page?.question)
  const hasChildren = React.isValidElement(children)

  const pressNext = usePress(() => {
    if (inSwipe.current && visible) return
    if (!hasQuestionAndNotSelectedChoice) {
      goToNextPage()
    }

    if (DEBUG) logger.log('goToNextPage', { hasQuestionAndNotSelectedChoice })
  })

  const pressPrevious = usePress(() => {
    if (inSwipe.current && visible) return
    goToPreviousPage()

    if (DEBUG) logger.log('goToPreviousPage', { hasQuestionAndNotSelectedChoice })
  })

  const scroll = useElementScroll()

  const onScroll = () => {
    pressNext.onInvalidatePress()
    pressPrevious.onInvalidatePress()
  }

  const onSelectQuizChoice = async (_choice: QuizChoice) => {
    setSelectedChoice(question?.id, _choice)
    AppStatus.set('loading')

    if (APIClient.Session.isLoggedIn()) {
      await APIClient.Learn.sendAnswer(question?.id, _choice?.id)
    }

    AppStatus.set('idle')
    answerModal.toggle()
  }

  const RenderQuizChoice = React.useCallback(({ item }) => {
    const isSelected = item?.id === selectedChoice?.id
    const selectedWrongChoice = !!selectedChoice && !isNeutral && !selectedChoice?.is_correct
    const isCorrect = item?.is_correct

    return (
      <LearnOption
        extraSpacing
        key={'learn-filter-' + item?.id}
        text={item?.label}
        onPress={() => onSelectQuizChoice(item)}
        selected={isSelected || (selectedWrongChoice && isCorrect)}
        isCorrect={isCorrect}
        isNeutral={isNeutral}
      />
    )
  }, [selectedChoice, onSelectQuizChoice])

  const hasVideo = !!page?.video

  const PressableOverlay = ({ inContent = false, style = {}}) => {
    if (hasQuestionAndNotSelectedChoice && inContent || hasChildren && inContent) return null

    const zIndex = inContent ? 2 : 0

    return (
      <View css={[styles.pressOverlay, { zIndex }, !inContent && { top: scroll?.offsetY }]}>
        <View
          onClick={pressPrevious.onPress}
          css={[styles.pressableOverlayArea, DEBUG && { background: '#FF05' }]}
        />

        <View
          onClick={pressNext.onPress}
          css={[styles.pressableOverlayArea, DEBUG && { background: '#F005' }]}
        />
      </View>
    )
  }

  const onNext = () => {
    if (!selectedChoice?.is_correct && !prevAnswers.current.find(answer => answer === currentPage)) {
      analytics.track('lesson_error')
      prevAnswers.current = [...prevAnswers.current, currentPage]
    }
    answerModal.toggle()
    setTimeout(() => goToNextPage(), 450)
  }

  const getModalAnswerType = (isCorrect: boolean) => {
    if (isNeutral) return 'neutral'
    return isCorrect ? 'correct' : 'incorrect'
  }

  const getModalAnswerTitle = (isCorrect: boolean) => {
    if (isNeutral) return question?.correct_feedback_title
    return (isCorrect) ? question?.correct_feedback_title : question?.incorrect_feedback_title
  }

  const getModalAnswerText = (isCorrect: boolean) => {
    if (isNeutral) return question?.correct_feedback
    return (isCorrect) ? question?.correct_feedback : question?.incorrect_feedback
  }

  const adjacentPage = currentPage - VIEWED_PREVIOUS_PAGES >= index || currentPage + VIEWED_NEXT_PAGES <= index

  if (!!page?.question && !question?.choices || (!page?.question && adjacentPage && !visible)) return <LoadingScreen transparent />

  return <>
    <div
      style={styles.content}
      onScroll={onScroll}
      ref={scroll.ref}
    >
      <LessonViewContent
        visible={visible}
        title={question?.title}
        description={question?.description}
        text={!page?.question ? page?.text : null}
        padding={!!page?.question || hasChildren}
        assignContentStyles={!!page?.question || hasChildren}
        autoWidth={hasChildren}
        renderOverlay={<PressableOverlay />}
      >
        {(page?.mobile_foreground || page?.web_foreground) ? (
          <Image
            source={isMobile ? page?.mobile_foreground : page?.web_foreground}
            style={styles.image}
            variants={[(page?.video || page?.text) && 'marginBottom:1', (!!question?.title || !!question?.description) && 'marginTop:1']}
            objectFit='contain'
          />
        ) : null}

        <PressableOverlay inContent />

        {!!page?.question ? ( // @ts-ignore
          <View
            css={[
              styles.quizChoiceList,
              { pointerEvents: hasQuestionAndNotSelectedChoice ? 'auto' : 'none' },
            ]}
          >
            {question?.choices?.map((item, i) => <RenderQuizChoice item={item} />)}
          </View>
        ) : null}

        {children}
      </LessonViewContent>

      {hasVideo ? <>
        <View
          css={[styles.pressableVideoOverlay, { top: scroll?.offsetY }]}
          onClick={() => {
            if (!inSwipe.current) setFullscreen(true)
          }}
        />

        <PlayerFullscreen
          url={page?.video}
          visible={fullscreen}
          toggle={() => setFullscreen(!fullscreen)}
        />
      </> : null}

      <LessonViewNext
        goToNext={goToNextPage}
        visible={(hasQuestionAndNotSelectedChoice || hasChildren) ? false : visible}
      />
    </div>

    <ModalLessonAnswer
      visible={answerModal.visible}
      toggle={answerModal.toggle}
      type={getModalAnswerType(selectedChoice?.is_correct)}
      title={getModalAnswerTitle(selectedChoice?.is_correct)}
      text={getModalAnswerText(selectedChoice?.is_correct)}
      onNext={onNext}
    />
  </>
}

const styles = variantProvider.createComponentStyle(theme => ({
  quizChoiceList: {
    height: 'auto',
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    marginTop: theme.spacing.value(1),
  },
  image: {
    zIndex: -1,
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    aspectRatio: (1920 / 1080),
    backgroundColor: 'transparent',

    [theme.media.down('small')]: {
      aspectRatio: (1920 / 3000),
    },
  },
  content: {
    minHeight: '100svh',
    maxHeight: '100svh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    position: 'relative',
    pointerEvents: 'all',
  },
  pressOverlay: {
    width: '100%',
    height: '100%',
    ...theme.presets.absolute,
    ...theme.presets.whole,
    backgroundColor: theme.colors.transparent,
    ...theme.presets.row,
    ...theme.presets.justifySpaceBetween,
  },
  pressableOverlayArea: {
    width: '50%',
  },
  pressableVideoOverlay: {
    backgroundColor: DEBUG ? '#0f25' : theme.colors.transparent,
    width: '100vw',
    height: 'calc(100svh * 0.5)',
    ...theme.presets.absolute,
    right: 0,
    left: 0,
    top: 0,
    zIndex: 5,
  },
}), true)
