import { ViewComposition, ViewPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()

const SEPARATOR_HEIGHT = 2

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle((t) => ({
    wrapper: {
      display: 'flex',
    },
  })),
  separator: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      height: SEPARATOR_HEIGHT,
      backgroundColor: theme.colors.neutral5,
    },
  })),
  'fullViewport': createViewStyle((theme) => ({
    wrapper: {
      width: '100vw',
      height: '100svh',
    },
  })),
  'overlap': createViewStyle((theme) => ({
    wrapper: {
      zIndex: 10,
    },
  })),
  'pointerEvents:none': createViewStyle((theme) => ({
    wrapper: {
      pointerEvents: 'none',
    },
  })),
  'rowReverse': createViewStyle((theme) => ({
    wrapper: {
      flexDirection: 'row-reverse',
    },
  })),
  'maxFullWidth': createViewStyle((theme) => ({
    wrapper: {
      maxWidth: '100%',
    },
  })),
  'overflow:hidden': createViewStyle((theme) => ({
    wrapper: {
      overflow: 'hidden',
    },
  })),
  'middleViewportHeight': createViewStyle((theme) => ({
    wrapper: {
      height: '60vh',
    },
  })),
  elevated: createViewStyle((theme) => ({
    wrapper: {

      ...theme.effects.sectionElevation,
      borderRadius: theme.borderRadius.large,
    },
  })),
  screenSized: createViewStyle((theme) => ({
    wrapper: {
      width: '100vw',
      height: '100svh',
      ...theme.presets.absolute,
      ...theme.presets.whole,
      ...theme.presets.center,
      zIndex: 5,
    }
  }))
}
