import { I18N } from '@/app'
import { APIClient } from '@/services'
import { OrganisationCategory } from '@/types'
import { useSearchParams } from '@codeleap/web'
import React, { SetStateAction } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'

const scoreTabs = [
  {
    label: I18N.t('organisations.reviewsTabTitle'),
    debugName: 'Reviews',
    value: 'reviews',
  },
  {
    label: I18N.t('organisations.successStoriesTabTitle'),
    debugName: 'Success stories',
    value: 'stories',
  },
]

type SelectableIndustry = OrganisationCategory['slug'] | 'all'

type Loader = ReturnType<typeof useScoresLoader>

type Params = {
  content: string
  reviewId: any
  category: string
}

type IScoresContext = {
  industry: SelectableIndustry
  setIndustry: React.Dispatch<SetStateAction<SelectableIndustry>>
  params: Params
  setParams: React.Dispatch<SetStateAction<Params>>
} & Loader

type ScoresContextProps = React.PropsWithChildren<{
  loader: ReturnType<typeof useScoresLoader>
}>

const _ScoresContext = createContext<IScoresContext>({} as IScoresContext)

const ScoresContextProvider = (props: ScoresContextProps) => {
  const { children, loader } = props

  const [params, _setParams] = useSearchParams({
    tab: scoreTabs?.[0]?.value,
    content: '',
    reviewId: null,
    category: loader?.profile?.data?.organization?.category?.slug ?? 'all',
  })

  const setParams = (p: Partial<Params>) => _setParams({ ...params, ...p })

  const industry = params.category as IScoresContext['industry']

  const setIndustry = (industry: SelectableIndustry) => {
    setParams({ category: industry })
  }

  const value:IScoresContext = {
    industry,
    setIndustry,
    params,
    setParams,
    ...loader,
  }

  return (
    <_ScoresContext.Provider value={value}>
      {children}
    </_ScoresContext.Provider>
  )
}

type Selector<T> = Parameters<(typeof useContextSelector<IScoresContext, T>)>[1]

function useScoresContext<T>(selector: Selector<T>) {
  return useContextSelector(_ScoresContext, selector)
}

const useIndustrySelector = () => {
  const industry = useScoresContext((context) => context.industry)
  const setIndustry = useScoresContext((context) => context.setIndustry)

  const _options = ScoresContext.useScoresContext(c => c.industryData.industries)

  const options = [
    { label: 'All Industries', value: 'all' },
    ..._options,
  ]

  const selectedOption = options.find((o) => o.value === industry)

  return {
    industry, setIndustry, options, selectedOption,
  }
}

const useScoresLoader = () => {
  const industryData = APIClient.Organisations.useIndustryOptions()
  const profile = APIClient.Session.useProfile()
  const questionCategories = APIClient.Organisations.useCategories()

  const isLoading = industryData.query.isLoading || (profile.isLoading && profile?.isFetching) || questionCategories.query.isLoading

  return {
    industryData, profile, questionCategories, isLoading,
  }
}

const useTabs = () => {
  const tab = useScoresContext((context) => context.params.tab)

  const setParams = useScoresContext((context) => context.setParams)

  const setTab = (tab) => {
    setParams({ tab })
  }

  return {
    tab, setTab,
  }
}
export const ScoresContext = {
  Provider: ScoresContextProvider,
  useIndustrySelector,
  useScoresLoader,
  useScoresContext,
  tabs: scoreTabs,
  storiesTab: scoreTabs[1],
  reviewsTab: scoreTabs[0],
  useTabs,
}
