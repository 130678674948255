import { Button, Icon, Image, Modal, Select, Text, TextInput, View } from '@/components'
import { APIClient } from '@/services'
import { OrganisationLogo } from './Logo'
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { AppForms, AppImages, I18N, Theme, useAppI18N, variantProvider } from '@/app'
import { PropsOf, TypeGuards, onUpdate, useForm, useValidate } from '@codeleap/common'
import { formatOrganisationEmployees, useModal } from '@/utils'
import { analytics } from '@/services'

type AddOrgRef = {
  setName: (name: string) => void
}

type AddOrgFormProps = {
  onSubmit: (values: any) => void
  visible: boolean
}

const AddOrgForm = React.forwardRef<AddOrgRef, AddOrgFormProps>(({ onSubmit, visible }, forwardedRef) => {
  const form = useForm(AppForms.registerOrganisationModal)
  const { t } = useAppI18N()

  const ref = useRef(null)

  const handleSubmit = () => {
    onSubmit(form.values)
  }

  useImperativeHandle(forwardedRef, () => ({
    setName: (name) => {
      form.setFieldValue('name', name)

      setTimeout(() => {
        const input: HTMLInputElement = ref.current.getInputRef()

        input.select()

      }, 100)
    },
  }), [form.setFieldValue])

  return <>
    <TextInput debugName='Organisation name' {...form.register('name')} ref={ref} type='text' />
    <TextInput debugName='Organisation URL' {...form.register('website')} />

    <Button debugName='Register organisation' onPress={handleSubmit} disabled={!form.isValid} text={t('SearchOrganisations.registerModal.submit')} />
  </>
})

const Item = (props) => {
  const { data: { item: data }, isSelected } = props

  if (data.is_unknown) return null

  const employeeInfo = formatOrganisationEmployees(data)

  return <View variants={['row', isSelected && 'bg:primary2', 'border-radius:small', 'alignCenter', 'padding:2']} css={styles.itemWrapper}>
    <OrganisationLogo logo={data.logo} size={'tiny'} />
    <View variants={['column', 'marginLeft:2']}>
      <Text text={data.name} variants={['h5']} />
      {
        employeeInfo && <Text text={employeeInfo} variants={['p4']} />
      }
      <Text text={data.website} variants={['p4']} />
    </View>
    <Icon
      debugName='Organisation Select Check'
      name='check'
      css={{
        visibility: isSelected ? 'visible' : 'hidden',
      }}
      variants={['iconSize:2', 'marginLeft:auto']}
    />
  </View>
}

const NoSearchPlaceholder = ({ onSelectUnknown = null }) => {
  const i18n = useAppI18N()
  return <View variants={['column', 'alignCenter']}>
    <Image source={AppImages.NoOrganisationSearched} css={styles.noSearchImage} />
    <Text variants={['textCenter']}>
      {i18n.t('organisations.searchPrompt')}
    </Text>
  </View>
}

const NotFoundPlaceholder = ({ search }) => {
  const { t } = useAppI18N()

  return <View variants={['column', 'alignCenter']}>
    <Image source={AppImages.OrganisationNotFound} css={styles.noSearchImage} />
    <Text variants={['textCenter']} text={t('organisations.noResults', {
      organisationName: search,
    })} />
  </View>
}

type OrganisationSelectProps = PropsOf<typeof Select> & {
}

// This is for the "Unknown organisation" text to always be visible
const LIST_MIN_HEIGHT = 200 + Theme.spacing.value(4)

const validateOrg = (value: string) => {
  let valid = true

  if (!TypeGuards.isNumber(value)) valid = false

  return {
    valid,
    message: I18N.t('form.validations.required'),
  }
}

export const OrganisationSelect = forwardRef((props: OrganisationSelectProps, selectRef) => {
  const { onValueChange, value, ...selectProps } = props
  const { t } = useAppI18N()

  const addOrganisationModal = useModal()
  const addOrganisationModalController = useRef<AddOrgRef>(null)
  const isSearching = useRef(false)

  const [selectedOrg, setSelectedOrg] = useState(value)

  const [search, setSearch] = useState('')

  const ref = useRef(null)
  useImperativeHandle(selectRef, () => ref?.current)


  const hasSearch = !!search?.trim()

  const unknownOrg = APIClient.Organisations.useUnknownOrganisation()
  const unknownOrganisation = unknownOrg?.organisation

  const validate = useValidate(value, validateOrg)

  const { create: createOrg } = APIClient.Organisations.organisationsManager.useCreate()

  const onChange = (item) => {
    onValueChange(item.id)
    setSelectedOrg({
      label: item.name,
      value: item.id,
      item: item,
    })

    setTimeout(() => {
      validate.onInputBlurred()
    }, 100)
  }

  const inputHidden = useRef(null)

  const onSelectUnknown = () => {
    if (!unknownOrg) return

    analytics.track('org_add', { source: 'search_screen' })
    onChange(unknownOrg.organisation)

    if (inputHidden.current) {
      setTimeout(() => {
        inputHidden.current?.focus?.()
      })
    }
  }

  const onPressAddOrganisation = () => {
    if (!!search?.trim?.()) {
      analytics.track('org_add', { source: 'search_results' })
      addOrganisationModalController?.current?.setName?.(search?.trim())
    }
    addOrganisationModal.toggle()
  }

  const onAddOrganisation = async (values) => {
    logger.log('Registering organisation', values)

    addOrganisationModal.toggle()

    try {
      const newOrg = await createOrg({
        website: values.website,
        name: values.name,
      })

      onChange(newOrg)
    } catch (e) {
      logger.error('Error registering organisation', e)
    }
  }

  const noSearch = <NoSearchPlaceholder onSelectUnknown={onSelectUnknown} />
  const noResults = <NotFoundPlaceholder search={search} />

  const Footer = useCallback(() => {
    if (!hasSearch) return null

    return <View variants={['padding:2', 'fullWidth']}>
      <Button
        onPress={onPressAddOrganisation}
        debugName='Add organisation'
        text={t('organisations.add')}
        icon='plus'
        variants={['outline', 'fullWidth']}
      />
    </View>
  }, [hasSearch, onPressAddOrganisation])

  const firstId = useRef(null)

  if (!unknownOrg?.organisation?.id) return null

  const unknownOrgOption = {
    label: unknownOrg?.organisation.name,
    value: unknownOrg?.organisation.id,
    item: unknownOrg?.organisation,
  }

  const loadOptions = async (search: string) => {
    const orgs = await APIClient.Organisations.organisationsManager.options.listItems(30, 0, {
      name: search,
      show_unverified: true,
    })

    firstId.current = orgs.results[0]?.id

    if (!search?.trim()) return []

    const results = orgs.results.map(i => ({
      label: i.name,
      value: i.id,
      item: i,
    }))

    return results
  }

  const isUnknown = value === unknownOrgOption.value

  const someOrgId = isUnknown ? undefined : value

  const onInputChange = (value) => {
    setSearch(value)
    if (!isSearching.current && value.trim().length >= 1) {
      isSearching.current = true
      analytics.track('org_search', { source_from: 'onboarding' })
    }
  }

  return <>
    <View variants={['fullWidth', 'relative']}>
      <Select
        loadOptions={loadOptions}
        searchable
        renderItem={Item}
        variants={['fullWidth']}
        PlaceholderComponent={() => hasSearch ? null : noSearch}
        PlaceholderNoItemsComponent={() => hasSearch ? noResults : noSearch}
        inputValue={search}
        onInputChange={(newValue) => onInputChange(newValue)}
        debugName='Organisation Select'
        FooterComponent={Footer}
        onValueChange={value => onValueChange(value ?? '')}
        value={someOrgId}
        defaultValue={unknownOrganisation?.id as any}
        {...selectProps}
        selectedOption={selectedOrg as any}
        setSelectedOption={(value) => setSelectedOrg(value ?? '')}
        // @ts-ignore
        ref={ref}
        validate={null}
        onBlur={() => {
          validate.onInputBlurred()
          isSearching.current = false
        }}
        onFocus={validate.onInputFocused}
        _error={(validate?.isValid || !!value) ? '' : validate.message}
        placeholder={isUnknown ? unknownOrganisation.name : selectProps?.placeholder}
        components={{
          ClearIndicator: null,
          SingleValue: (o) => {
            if (isUnknown) {
              return <Text text={unknownOrganisation?.name} />
            }

            return <Text text={o.data.label} />
          },
        }}
        escapeClearsValue
        clearable
        styles={{
          list: {
            minHeight: LIST_MIN_HEIGHT,
          },
        }}
      />
      <TextInput
        // HACK it is impossible to take the focus of the select, unless focusing on another input (unknown org fix)
        styles={{ 'wrapper': styles.inputHidden }}
        debugName='input:selectOrg'
        ref={inputHidden}
        value={null}
        onChangeText={() => null}
      />
    </View>

    <Modal
      {...addOrganisationModal}
      title={t('organisations.registerModal.title')}
      showClose
      styles={{ box: styles.box }}
      variants={['centered', 'boxPadding:4']}
    >
      <AddOrgForm
        onSubmit={onAddOrganisation}
        visible={addOrganisationModal.visible}
        ref={addOrganisationModalController}
      />
    </Modal>
  </>
})

const styles = variantProvider.createComponentStyle(theme => ({
  box: {
    width: `calc(100vw - ${theme.spacing.value(4)}px)`,
    maxWidth: 350,
  },
  noSearchImage: {
    height: 120,
    aspectRatio: 1.125,
    ...theme.spacing.marginBottom(4),
  },
  itemWrapper: {
    '&:hover': {
      backgroundColor: theme.colors.primary1,
    },
    cursor: 'pointer',
  },
  inputHidden: {
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    left: 0,
    top: 0,
    zIndex: -9999,
  },
}), true)
