import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { TypeGuards } from '@codeleap/common'
import { navigate as _navigate } from 'gatsby'

export function authWall<T extends(...args: any[]) => any>(onAuth?: T, navigate = false) {
  return (...args: Parameters<T>) => {
    const profile = APIClient.Session.QueryKeys.me.getData()
    if (!profile) {
      if (navigate) _navigate('/auth/signup')
      else AppStatus.setModal('authWall')
      return
    }

    if (TypeGuards.isFunction(onAuth)) {
      return onAuth?.(...args)
    }
  }

}

export const unknownOrgWall = (onAuth?: () => any) => {
  return () => {
    const profile = APIClient.Session.QueryKeys.me.getData()

    if (profile?.organization?.is_unknown) {
      AppStatus.setModal('unknownOrganisation')
      return
    }

    if (TypeGuards.isFunction(onAuth)) {
      onAuth?.()
    }
  }
}

export const useAuthWall = () => {
  const session = APIClient.Session.useSession()

  return !session.profile
}
