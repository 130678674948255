import { api, React } from '@/app'
import { onUpdate, PaginationResponse, QueryManager, useI18N, useState } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { Achievement, AchievementLevel, Chapter, Module, Page } from '@/types'
import { useFlatlistProps } from '@/utils'
import { modulesManager } from './modules'
import { isLoggedIn, QueryKeys, useSession } from '../session'
import { achievementsManager } from '../achievements'
import { verifyAchievementProgress } from '../../../components/Achievements/Store'

const BASE_URL = 'learn/chapters/'

export const readPage = async (page: Page['id']) => {
  const response = await api.get(BASE_URL + 'read_page/', {
    params: {
      page,
    },
  })

  return response.data
}

export const chaptersManager = new QueryManager({
  itemType: {} as Chapter,
  name: 'chapters',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Chapter>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Chapter>(`${BASE_URL}${id}/`)

    return response.data
  },
})

type UseChapters = {
  moduleId: Module['id']
}

export const useChapters = ({ moduleId }: UseChapters) => {
  const { t } = useI18N()

  const chaptersStatus = [
    {
      label: t('learn.chapterScreen.filterStatus.pending'),
      value: false,
      debugName: 'select filter in progress chapter',
    },
    {
      label: t('learn.chapterScreen.filterStatus.done'),
      value: true,
      debugName: 'select filter done chapter',
    },
  ]

  const [status, setStatus] = useState(chaptersStatus?.[0]?.value)

  const module = modulesManager.useItem({
    id: moduleId,
  })

  const chapters = chaptersManager.use({
    filter: {
      module: moduleId,
    },
  })

  const { isLoggedIn } = useSession()

  onUpdate(() => {
    chapters?.refresh()
    module?.refresh()
  }, [isLoggedIn])

  const data = React.useMemo(() => {
    if (!chapters?.items) return []

    return chapters?.items?.filter(chapter => chapter?.done === status)
  }, [status, chapters?.items, chapters?.isRefreshing])

  const listProps = useFlatlistProps(chapters, {
    noMoreItemsText: t('learn.noMoreLessons'),
    forceLoading: module?.query?.isFetching,
  })

  return {
    chaptersListProps: {
      ...listProps,
      data,
    },
    chaptersStatus,
    chapters,
    status,
    setStatus,
    data,
    moduleQuery: module,
    module: module?.data,
  }
}

export const verifyCollectionAchievementProgress = async (achievements: Achievement[]) => {
  if (!isLoggedIn()) return
  const metric = 'collections_completed'

  const collectionAchievement = achievements?.find(a => a?.progression_metric == metric)
  const data = await achievementsManager.actions.getProgress(metric)

  const currentLevel = collectionAchievement?.levels?.list[collectionAchievement?.levels?.current_level_index]
  const comparedLevel = data?.achievement_progress?.[0]

  if (comparedLevel?.id !== currentLevel?.id) {
    verifyAchievementProgress(data?.achievement_progress, false)
  }
}
