import { useAppI18N } from '@/app'
import { AppStatus } from '@/redux'
import { analytics, APIClient } from '@/services'
import { Button, View } from '..'
import { authWall } from '@/utils'

export const OrgReviewButtons = ({ onShare, isMyOrganisation = false }) => {
  const { t } = useAppI18N()
  const pulse = APIClient.PulseQuestions.usePulseQuestions()

  const onPressOrganisation = () => {
    if (!pulse.available) {
      AppStatus.setModal('noReviewQuestions')
      return
    }

    pulse.openQuestions('org_page')
  }

  const onPressStories = async () => {
    analytics.track('story_add_start', { source_from: 'org' })
    AppStatus.setModal('createStory')
  }

  if (!isMyOrganisation) return null

  return (
    <View variants={['column', 'gap:2', 'fullWidth', 'paddingHorizontal:2']}>
      <View variants={['gap:1', 'fullWidth']}>
        <Button
          debugName='Add your views'
          onClick={authWall(onPressOrganisation)}
          variants={['outline', 'large', 'fullWidth']}
          icon='edit'
          text={t('organisations.addYourViews')}
        />
        <Button
          debugName='Success Stories'
          onClick={authWall(onPressStories)}
          variants={['outline', 'large', 'fullWidth']}
          icon='plus'
          text={t('organisations.addSuccessStory')}
        />
      </View>
      {
        onShare ? (
          <Button
            debugName='Invite to review'
            onPress={onShare}
            variants={['outline', 'large']}
            text={t('organisations.inviteColleagues')}
          />
        ) : null
      }
    </View>
  )
}
