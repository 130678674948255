import { useAct } from '@/utils'
import { Button, Icon, Image, Text, View } from '..'
import { AppImages, useAppI18N, variantProvider } from '@/app'

const BREAKPOINT = 'small'

export const Act = () => {
  const { t } = useAppI18N()

  const { onPressOrganisation } = useAct()

  return <View variants={['column', 'alignCenter', 'justifyCenter', 'gap:4']}>
    <View
      variants={['column', 'gap:4']}
      responsiveVariants={{ small: ['gap:2'] }}
    >
      <View variants={['bg:primary1', 'border-radius:small', 'alignCenter', 'justifyCenter']} css={styles.imageWrapper}>
        <Image variants={['marginTop:auto']} css={styles.image} source={AppImages.Contribute} />
      </View>

      <View
        variants={['gap:1', 'row', 'alignCenter', 'justifyCenter']}
        responsiveVariants={{ [BREAKPOINT]: ['justifyStart'] }}
      >
        <Icon
          name='empty-check-circle'
          variants={['iconSize:2']}
          debugName={'Act message check icon'}
        />
        <Text text={t('act.act.message')} variants={['p2', 'color:neutral5']} />
      </View>

      <Text
        variants={['textCenter', 'color:neutral5']}
        responsiveVariants={{ small: ['paddingHorizontal:0', 'textLeft'] }}
        text={t('act.act.description')}
      />
    </View>

    <Button
      variants={['outline', 'fullWidth']}

      debugName={'OrganisationButton'}
      onPress={onPressOrganisation}
      text={t('act.act.buttonOrg')}
      icon={'edit'}
    />

  </View>
}

const IMAGE = {
  default: 350,
  small: 120,
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    [theme.media.down('small')]: {
      maxHeight: '100svh',
    },
  },
  imageWrapper: {
    width: 'auto',

    backgroundColor: theme.colors.primary1,
    // maxWidth: '100%',
    // overflow: 'hidden',

    // [theme.media.down('small')]: {
    //   height: IMAGE.small,
    // },
  },
  image: {

    height: IMAGE.small,
  },
}), true)

