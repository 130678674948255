import { useCallback } from 'react'
import { ScoresContext } from './context'
import { Select, View } from '..'
import { authWall } from '@/utils'
import { analytics } from '@/services'

export const IndustrySelector = () => {
  const { setIndustry, selectedOption, options, industry } = ScoresContext.useIndustrySelector()

  const onSelect = useCallback((o) => {
    analytics.track('industry_filter', {
      industry: options.find(({ value }) => value === o.value)?.label,
    })

    authWall(() => {
      setIndustry(o.value)
    })()
  }, [setIndustry])

  return <Select
    searchable
    loadInitialValue
    options={options}
    // value={selectedOption?.value}
    // onValueChange={setIndustry}

    selectedOption={selectedOption}
    setSelectedOption={onSelect}
    debugName='Industry Select'
    variants={['noError', 'fullWidth']}

  />

}
