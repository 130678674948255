import { AppStatus } from '@/redux'
import { analytics, AnalyticsEvents, APIClient } from '@/services'
import { authWall } from '../authWall'

export function useAct() {
  const pulse = APIClient.PulseQuestions.usePulseQuestions()
  const { isLoggedIn, profile } = APIClient.Session.useSession()

  async function handleValidations() {
    if (!isLoggedIn) {
      authWall(() => {}, true)()
      return false
    }

    const signupComplete = await APIClient.Session.checkSignupCompletion(profile)
    if (!signupComplete.isComplete) {
      // @ts-ignore
      AppStatus.setModal(signupComplete.modal ?? signupComplete.nextStep)
      return false
    }

    return true
  }

  async function onPressOrganisation() {
    if (await handleValidations()) {
      AnalyticsEvents.pressContribute()
      if (!pulse.available) {
        AppStatus.setModal('noReviewQuestions')
        return
      }
      pulse.openQuestions('act_page')
    }
  }

  async function onPressStories() {
    if (await handleValidations()) {
      AnalyticsEvents.pressContribute()
      analytics.track('story_add_start', { source_from: 'act' })
      AppStatus.setModal('createStory')
    }
  }

  return {
    onPressOrganisation,
    onPressStories,
  }
}
