import { AppImages, React, variantProvider } from '@/app'
import { Modal, Image, View, Text, Button } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { useIsMobile } from '@/utils'
import { navigate } from 'gatsby'

const title = 'Here we go!'
const description = 'Congrats! From learning to community-led action, everything you need to tackle climate change is just a tap away.'

export function HereWeGoModal() {
  const visible = useAppSelector((state) => state.AppStatus.modals.hereWeGo)
  const toggle = () => AppStatus.setModal('hereWeGo')
  const isMobile = useIsMobile()
  const { profile } = APIClient.Session.useSession()

  const onPress = () => {
    navigate('/learn/')
    toggle()
  }

  return (
    <Modal
      toggle={toggle}
      showClose={false}
      visible={visible}
      variants={[isMobile && 'fullscreen', 'centered']}
      styles={{ box: styles.modalBox, body: styles.modalBody }}
    >

      <Image style={styles.image} source={AppImages.HereWeGo} />

      <View variants={['column', 'gap:4', 'padding:4']}>
        <View variants={['column', 'gap:1', 'center']}>
          <Text variants={['h1']} text={title} />
          <Text variants={['p1', 'textCenter']} text={description} />
          <Text variants={['p1', 'row']}>
        Username:{'\u00A0'}<Text variants={[`h5`]} text={profile?.display_name}/>
          </Text>
        </View>

        <Button debugName='next-btn' variants={['fullWidth']} text='Get Started' onPress={onPress} />
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 416

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    modalBox: {
      ...theme.spacing.padding(0),
      // @ts-ignore
      borderRadius: theme.borderRadius.large,
      width: MODAL_WIDTH,
      maxWidth: `calc(100vw - ${theme.spacing.value(4)}px)`,
      backgroundColor: theme.colors.primary1,

      [theme.media.down('small')]: {
        position: 'fixed',
        overflowY: 'auto',
        ...theme.presets.fullWidth,
        height: '100%',
        borderRadius: 0,
        maxWidth: `100vw`,
      },
    },
    modalBody: {
      [theme.media.down('small')]: {
        height: '100%',
        maxHeight: 'none',
      },
    },
    actionIcon: {
      top: 24,
      right: 24,
    },

    image: {
      ...theme.presets.fullWidth,
      height: 'auto',
    },
  }),
  true,
)
