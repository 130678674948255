import { EmployeeReviews, Reviews } from '../Organisations'
import { ScoresContext } from './context'

export const ScoreReviews = () => {
  const params = ScoresContext.useScoresContext(c => c.params)
  const setParams = ScoresContext.useScoresContext(c => c.setParams)

  return <EmployeeReviews
    {...params}
    setParams={setParams}
  />

}
