import { I18N, IS_SSR, Settings, api } from '@/app'
import { Achievement, AchievementLevel, Answer, Chapter, Module, Organisation, Story } from '@/types'
import { create } from 'zustand'
import { AnalyticsEvents, SharePayload, analytics } from '../services/analytics'
import { APIClient } from '@/services'

const handleShared = (p: SharePayload) => {
  return (action) => {

    AnalyticsEvents.share(p)
  }
}

type ShareStore = {
  setShare: (data: ShareData) => void
  data: ShareData
}

type ShareContent = {
  message: string
  url: string
  title: string
}

const shareStore = create<ShareStore>((set) => ({
  data: null,
  setShare: (data: ShareData | null) => {
    return set({ data })
  },
}))

const getWebsite = () => {
  if (IS_SSR) return ''

  return window?.location?.origin
}

const share = async (content: ShareContent) => {
  const isMobileShare = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

  const shareData: ShareData = {
    text: content?.message,
    url: content?.url,
    title: content?.title,
  }

  const shareable = (!!navigator.share && navigator.canShare(shareData)) && isMobileShare

  if (!shareable) {
    shareStore.setState({ data: shareData })
  } else {
    await navigator.share(shareData)
    await APIClient.Session.sharedApp()
  }
}

export const shareOrganisation = (organisation: Organisation) => {
  const address = `${getWebsite()}/organisations/${organisation.id}`

  const message = I18N.t('share.organisation.message', {
    appName: Settings.AppName,
    address,
    organisation: organisation.name,
  })

  share({
    message,
    url: address,
    title: organisation.name,
  }).then(() => {
    handleShared({
      type: 'organisation',
      id: organisation.id,
      name: organisation.name,
    })
    analytics.track('org_share', { name: organisation.name })
  })
}

export const shareReview = (organisation: Organisation, review: Answer) => {
  const address = `${getWebsite()}/organisations/${organisation.id}?tab=reviews&reviewId=${review.id}`

  const message = I18N.t('share.review.message', {
    appName: Settings.AppName,
    address,
    organisation: organisation.name,
  })

  share({
    message,
    url: address,
    title: organisation.name,
  }).then(
    handleShared({
      type: 'review',
      id: review.id,
      name: organisation.name,
    }),
  )
}

export const shareStory = (story: Story) => {
  const address = `${getWebsite()}/learn/?section=stories&storyId=${story.id}`

  const message = I18N.t('share.story.message', {
    appName: Settings.AppName,
    address,
  })

  share({
    message,
    url: address,
    title: story.headline,
  }).then(
    handleShared({
      type: 'successStory',
      id: story.id,
      name: story.headline,
    }),
  )
}

export const shareModule = (module: Module) => {
  const address = `${getWebsite()}/learn/chapter/${module.id}`

  const message = I18N.t('share.collection.message', {
    appName: Settings.AppName,
    address,
    module: module.title,
  })

  share({
    message,
    url: address,
    title: module.title,
  }).then(
    handleShared({
      type: 'collection',
      id: module.id,
      name: module.title,
    }),
  )
}

export const shareLessonProgress = (chapter: Chapter) => {
  const address = `${getWebsite()}/learn/lessonView/${chapter.id}`

  const message = I18N.t('share.lessonProgress.message', {
    appName: Settings.AppName,
    address,
    module: chapter.title,
  })

  share({
    message,
    url: address,
    title: chapter.title,
  }).then(
    handleShared({
      type: 'learnProgress',
      id: chapter.id,
      name: chapter.title,
    }),
  )
}

export const shareAchievement = (achievement: Achievement) => {
  const address = `${getWebsite()}/`

  const message = I18N.t('share.achievement.message', {
    appName: Settings.AppName,
    achievementName: achievement?.name,
  })
  analytics.track('badge_manual_share')
  share({
    message,
    url: address,
    title: achievement.name,
  })
}

export const shareAchievementLevel = (level: AchievementLevel) => {
  const address = `${getWebsite()}/`

  const message = I18N.t('share.achievementLevel.message', {
    levelName: level?.name,
    achievementName: level?.achievement?.name,
  })

  analytics.track('badge_manual_share')

  share({
    message,
    url: address,
    title: level.name,
  })
}

export const shareApp = () => {
  Share.share({
    url: '/organisations',
    message: '',
    title: I18N.t('share.app.dialog'),
  })
}

export const Share = {
  share,
  getWebsite,
  useShareStore: shareStore,
}

export const achievementShareUrl = (level?: AchievementLevel) => {
  const timestamp = new Date().getTime().toString()
  const profileId = APIClient.Session.QueryKeys.me.getData()?.id
  return `${api.config.baseURL}achievements/levels/${level?.id}/share/?ts=${timestamp}&profileId=${profileId}`
}
