import { api, React } from '@/app'
import { onUpdate, PaginationResponse, QueryManager, ReactQuery, TypeGuards, useI18N } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { Module } from '@/types'
import { getListQueryParams, useFlatlistProps } from '@/utils'
import { useSession } from '../session'

const BASE_URL = 'learn/'

export const modulesManager = new QueryManager({
  itemType: {} as Module,
  name: 'modules',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {

    const response = await api.get<PaginationResponse<Module>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
        tags: getListQueryParams(filters?.tags),
        formats: getListQueryParams(filters?.formats),
        sources: getListQueryParams(filters?.sources),
        themes: getListQueryParams(filters?.themes),
      },
    })

    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Module>(`${BASE_URL}${id}/`)

    return response.data
  },
})

const queryKeys = {
  filterOptions: ['learn-filter-options'],
}

export function useLearnFilterOptions() {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.filterOptions,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await api.get(`${BASE_URL}filters/`)
      return response.data
    },
  })

  return query.data
}

type ModuleFilters = {
  search: string
  formats: string[]
  sources: string[]
  tags: string[]
  themes: string[]
}

type UseModulesParams = {
  sources?: string | string[]
}

export const useModules = ({ sources: _sources = null }: UseModulesParams) => {
  const { t } = useI18N()
  const { isLoggedIn } = useSession()

  const moduleStatus = [
    {
      label: t('learn.learnScreen.filterStatus.all'),
      value: null,
      debugName: 'select filter all lessons',
    },
    {
      label: t('learn.learnScreen.filterStatus.progress'),
      value: false,
      debugName: 'select filter in progress lessons',
    },
    {
      label: t('learn.learnScreen.filterStatus.done'),
      value: true,
      debugName: 'select filter done lessons',
    },
  ]

  const sources = React.useMemo(() => {
    if (_sources.length === 0) return []
    return TypeGuards.isArray(_sources) ? _sources : [_sources]
  }, [_sources])

  const [status, setStatus] = React.useState(moduleStatus?.[0]?.value)
  const [filter, setFilter] = React.useState<ModuleFilters>(() => ({
    search: '',
    formats: [],
    sources,
    tags: [],
    themes: [],
  }))

  const modules = modulesManager.use({ filter })

  const filterOptions = useLearnFilterOptions()

  const listProps = useFlatlistProps(modules, {
    noMoreItemsText: t('learn.noMoreCollections'),
  })

  const data = React.useMemo(() => {
    if (TypeGuards.isNil(status)) return modules?.items

    if (status === false) {
      return modules?.items?.filter(module => module?.done === status && module?.progress > 0)
    }

    return modules?.items?.filter(module => module?.done === status)
  }, [status, modules?.items])

  onUpdate(() => {
    if (isLoggedIn) {
      modules?.refresh()
    }
  }, [isLoggedIn])

  return {
    modulesListProps: {
      ...listProps,
      data,
    },
    moduleStatus,
    status,
    setStatus,
    data,

    filter,
    setFilter,

    filterOptions,
  }
}

export type UseModulesReturn = ReturnType<typeof useModules>
