import { React, variantProvider } from '@/app'
import { CardBase, CardBaseProps, Icon, Image, View, Text } from '@/components'
import { AppStatus } from '@/redux'
import { analytics, APIClient, getSharedSettings } from '@/services'
import { Chapter } from '@/types'
import { useIsMobile } from '@/utils'
import { arePropsEqual, TypeGuards, useI18N } from '@codeleap/common'
import { navigate } from 'gatsby'

export type ChapterCardProps = Partial<Omit<CardBaseProps, 'debugName'>> & {
  chapter: Chapter
}

const WAIT_SPLASH_TIMEOUT = 300
const DONE_TRANSiTION = 4000

const ChapterCardComponent = (props: ChapterCardProps) => {
  const {
    chapter,
    ...rest
  } = props

  const { isLoggedIn } = APIClient.Session.useSession()

  const openChapterPage = () => {
    if (chapter.id !== getSharedSettings()?.initial_lesson && !isLoggedIn) {
      navigate('/auth/signup/')
      return
    }

    AppStatus.set('splash')
    setTimeout(() => {
      navigate(`/learn/lessonView/${chapter?.id}`)
    }, WAIT_SPLASH_TIMEOUT)

    setTimeout(() => {
      analytics.track('lesson_view', { lesson_name: chapter.title })
      AppStatus?.set('idle')
    }, DONE_TRANSiTION)
  }

  const { t } = useI18N()

  const isMobile = useIsMobile()

  const image = React.useMemo(() => {
    if (isMobile) {
      return chapter?.mobile_image ?? chapter?.image
    } else {
      return chapter?.web_image ?? chapter?.image
    }
  }, [isMobile])

  return (
    <CardBase
      variants={['card:learn']}
      debugName='Chapter card'
      onPress={openChapterPage}
      gap={null}
      {...rest}
    >
      {TypeGuards.isString(image) ? (
        <View style={styles.imageWrapper}>
          <Image
            objectFit='cover'
            source={image}
            style={styles.image}
          />
        </View>
      ) : null}

      <View variants={['column', 'alignStart', 'justifyStart', 'padding:2', 'fullWidth', 'flex']}>
        <View variants={['row', 'fullWidth', 'justifySpaceBetween', 'alignCenter']}>
          <View variants={['row', 'center']}>
            <Icon debugName='chapter card icon' name='clock' size={iconSize} variants={['neutral5']} />
            <Text variants={['p4', 'color:neutral5', 'marginLeft:0.5']} text={`${chapter?.duration ?? 2} ${t('learn.components.cards.chapter.duration')}`} />
          </View>

          {chapter?.done ? (
            <Icon debugName='chapter card icon done' name='check-circle' variants={['originalColor', 'iconSize:1']} />
          ) : null}
        </View>

        <Text variants={['h5', 'color:neutral6', 'marginTop:1']} text={chapter?.title} />
        {chapter?.description ? <Text variants={['p3', 'color:neutral5', 'marginTop:1']} text={chapter?.description} /> : null}
      </View>
    </CardBase>
  )
}

export const ChapterCard = React.memo(ChapterCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['chapter'],
  })
})

const iconSize = 12

const styles = variantProvider.createComponentStyle(theme => ({
  imageWrapper: {
    height: 'auto',
    maxWidth: '25%',
    flex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    borderBottomLeftRadius: theme.borderRadius.small,
    borderTopLeftRadius: theme.borderRadius.small,
  },
}), true)
