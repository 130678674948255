import { includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'

export type CardBaseParts = 'wrapper' | 'innerWrapper'
export type CardBaseState = 'pressable'

export type CardBaseComposition = CardBaseParts | `${CardBaseParts}:${CardBaseState}`

const createCardBaseStyle = variantProvider.createVariantFactory<CardBaseComposition>()

export const CardBasePresets = includePresets((style) => createCardBaseStyle(() => ({ wrapper: style })))

const defaultStyles = CardBasePresets

export const CARD_BASE_HEIGHT = {
  collection: 135,
  lesson: 150,
}

export const AppCardBaseStyles = {
  ...defaultStyles,
  default: createCardBaseStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.padding(2),
      ...theme.presets.column,
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignStart,
    },
    innerWrapper: {
      ...theme.presets.column,
      maxWidth: '100%',
    },
    'wrapper:pressable': {
      cursor: 'pointer',
      transition: 'opacity 0.2s',

      '&:hover': {
        opacity: 0.8,
      },
    },
  })),
  'card:elevated': createCardBaseStyle(theme => ({
    wrapper: {
      ...theme.effects.light,
    },
  })),
  review: createCardBaseStyle(theme => ({
    wrapper: {
      ...theme.presets.fullWidth,
      maxWidth: 750,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
    },
  })),
  'card:hover': createCardBaseStyle(theme => ({
    wrapper: {
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
  })),
  'card:learn': createCardBaseStyle(theme => {
    return {
      wrapper: {
        width: '100%',
        minHeight: CARD_BASE_HEIGHT.lesson,
        maxHeight: CARD_BASE_HEIGHT.lesson,
        overflow: 'hidden',
        ...theme.effects.thin,
        borderRadius: theme.borderRadius.small,
        ...theme.spacing.padding(0),

        [theme.media.down('mid')]: {
          minHeight: CARD_BASE_HEIGHT.lesson * 1.2,
          maxHeight: CARD_BASE_HEIGHT.lesson * 1.2,
        },

        [theme.media.down('small')]: {
          minHeight: CARD_BASE_HEIGHT.collection,
          maxHeight: 'unset',
        },
      },
      innerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: CARD_BASE_HEIGHT.lesson,
        width: '100%',
        height: '100%',
        ...theme.spacing.padding(0),

        [theme.media.down('mid')]: {
          minHeight: CARD_BASE_HEIGHT.lesson * 1.2,
        },

        [theme.media.down('small')]: {
          minHeight: CARD_BASE_HEIGHT.collection,
        },
      },
    }
  }),
  'card:collection': createCardBaseStyle(theme => {
    return {
      wrapper: {
        width: '100%',
        minHeight: CARD_BASE_HEIGHT.collection,
        maxHeight: CARD_BASE_HEIGHT.collection,
        ...theme.effects.thin,
        borderRadius: theme.borderRadius.small,
        ...theme.spacing.padding(0),
      },
      innerWrapper: {
        minHeight: CARD_BASE_HEIGHT.collection,
        maxHeight: CARD_BASE_HEIGHT.collection,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        ...theme.spacing.padding(0),
        overflow: 'hidden',
      },
    }
  }),
  'card:TouchableEffect': createCardBaseStyle(theme => ({
    wrapper: {
      transition: 'opacity 0.2s',
      ':hover': {
        opacity: 0.8,
      },
    },
  })),
  'card:faded': createCardBaseStyle(theme => ({
    wrapper: {
      opacity: 0.5,
    },
    'wrapper:pressable': {
      cursor: 'not-allowed',

      '&:hover': {
        opacity: 0.5,
      },
    },
  })),
  'card:review': createCardBaseStyle(theme => ({
    wrapper: {
      ...theme.effects.reviewElevation,
    },
  })),
  'card:achievement': createCardBaseStyle(theme => ({
    wrapper: {
      ...theme.presets.row,
    },
    innerWrapper: {
      ...theme.presets.row,
      ...theme.presets.fullWidth,
    },
  })),
  'card:disabled': createCardBaseStyle(theme => ({
    wrapper: {
      opacity: 0.7,
      backgroundColor: theme.colors.neutral3,
    },
  })),
}
