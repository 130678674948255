import { PaginationResponse, QueryManager, ReactQuery, useState } from '@codeleap/common'
import { queryClient } from './queryClient'
import { api } from '@/app'
import { Answer, Organisation, OrganisationCategory, OverallScoreRank } from '@/types'
import { useProfile } from './session'

const BASE_URL = 'questions/answers/'

export type AnswerFilters = {
  reviewId?: number
  content?: string
  organisation?: Organisation['id']
  category?: OrganisationCategory['slug']
  limit?: number
}

export const answersManager = new QueryManager({
  itemType: {} as Answer,
  name: 'answers',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: AnswerFilters) => {

    const response = await api.get<PaginationResponse<Answer>>(BASE_URL, {
      params: {
        limit: filters?.limit ?? limit,
        offset,
        ...filters,
        search: filters?.content,
        organization: filters.organisation,
      },
    })

    return response.data
  },

  retrieveItem: async (id) => {
    const response = await api.get<Answer>(`${BASE_URL}${id}/`)

    return response.data
  },

})

const scoreRanksKey = ['scoreRankings']

const getScoreRanks = async () => {
  const res = await api.get<PaginationResponse<OverallScoreRank>>(`questions/score_ranks/`)

  return res.data.results
}

export const prefetchScoreRanks = () => {
  queryClient.client.prefetchQuery(scoreRanksKey, getScoreRanks)
}

export const useScoreRanks = () => {
  const query = ReactQuery.useQuery(
    scoreRanksKey,
    getScoreRanks,
  )

  return query
}

export const useAnswers = (filters?: AnswerFilters) => {
  const [search, setSearch] = useState('')

  const profile = useProfile()

  const answers = answersManager.use({
    filter: {
      content: search,
      ...filters,
      limit: !!profile?.data?.id ? 10 : 5,

    },
  })

  return {
    answers,
    search,
    setSearch,
  }
}
