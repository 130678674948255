import { CARD_BASE_HEIGHT, React, variantProvider } from '@/app'
import { CardBase, CardBaseProps, Icon, Image, View, Text, ProgressBar } from '@/components'
import { APIClient, analytics, useSharedSettings } from '@/services'
import { Module } from '@/types'
import { useIsMobile } from '@/utils'
import { arePropsEqual, TypeGuards, useI18N, useMemo } from '@codeleap/common'
import { navigate } from 'gatsby'

export type ModuleCardProps = Partial<Omit<CardBaseProps, 'debugName'>> & {
  module: Module
  width?: number
}

const ModuleCardComponent = (props: ModuleCardProps) => {
  const {
    module,
    ...rest
  } = props

  const { isLoggedIn } = APIClient.Session.useSession()

  const { t } = useI18N()
  const initialLesson = useSharedSettings()?.settings?.initial_lesson

  const isModuleFromInitialLesson = useMemo(() => {
    if (TypeGuards.isNil(module.chapters) || module.chapters.length === 0) return false
    return module?.chapters?.some(c => c.id === initialLesson)
  }, [module, initialLesson])

  const disabled = !isModuleFromInitialLesson && !isLoggedIn

  const openModule = React.useCallback(() => {
    if (!isModuleFromInitialLesson && !isLoggedIn) {
      navigate('/auth/signup/')
      return
    }

    if (module?.is_placeholder) {
      return
    }

    analytics.track('collection_view', { collection_name: module.title })
    navigate(`/learn/chapter/${module?.id}`)
  }, [module?.id, isLoggedIn])

  const hasMultipleChapters = module?.chapters?.length > 1

  const labelChapters = hasMultipleChapters ? t('learn.components.cards.module.chaptersIndicator') : t('learn.components.cards.module.chapterIndicator')

  const isMobile = useIsMobile()

  const image = React.useMemo(() => {
    if (isMobile) {
      return module?.mobile_image ?? module?.image
    } else {
      return module?.web_image ?? module?.image
    }
  }, [isMobile])

  const hasImage = TypeGuards.isString(image)

  return (
    <CardBase
      variants={['card:collection', module?.is_placeholder && 'card:faded', disabled && 'card:disabled']}
      debugName='Module card'
      onPress={openModule}
      gap={null}
      {...rest}
    >
      {hasImage ? (
        <View style={styles.imageWrapper}>
          <Image
            objectFit='cover'
            source={image}
            style={styles.image}
          />
        </View>
      ) : null}

      <View variants={['column', 'alignStart', 'justifyStart', 'padding:2', 'fullWidth', 'flex', 'fullHeight']} style={{ maxWidth: `calc(100% - ${hasImage ? IMAGE_WIDTH : ''})` }}>
        <View variants={['row', 'fullWidth', 'justifySpaceBetween', 'alignCenter']}>
          <View variants={['row', 'center']}>
            <Icon debugName='Module card icon' name='book' size={iconSize} variants={['neutral5']} />
            <Text variants={['p4', 'color:neutral5', 'marginLeft:0.5']} text={`${module?.chapters?.length} ${labelChapters}`} />
          </View>

          <View style={styles.progressBar}>
            <ProgressBar
              progress={module?.progress * 10}
              text={`${Math.round(module?.progress * 10)}%`}
            />
          </View>
        </View>

        <Text variants={['h5', 'color:neutral6', 'marginTop:1']} style={styles.text} text={module?.title} />
      </View>
    </CardBase>
  )
}

export const ModuleCard = React.memo(ModuleCardComponent, (previous, next) => {
  return arePropsEqual(previous, next, {
    check: ['module'],
  })
})

const iconSize = 12

const IMAGE_WIDTH = '25%'

const styles = variantProvider.createComponentStyle(theme => ({
  imageWrapper: {
    height: 'auto',
    minWidth: IMAGE_WIDTH,
    maxWidth: IMAGE_WIDTH,
    flex: 1,
    overflow: 'hidden',
    maxHeight: CARD_BASE_HEIGHT.collection,
    minHeight: CARD_BASE_HEIGHT.collection,
  },
  image: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    borderBottomLeftRadius: theme.borderRadius.small,
    borderTopLeftRadius: theme.borderRadius.small,
  },
  progressBar: {
    ...theme.presets.center,
    marginRight: theme.spacing.value(0.5),
  },
  text: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
  },
}), true)
