import { IS_SSR } from '@/app'
import { useLocation } from '@reach/router'

export function useReadSearchParams() {
  if (IS_SSR) return {}

  const params = useLocation().search

  const search = new URLSearchParams(params)

  const result = {}

  for (const [key, value] of search) {
    result[key] = value
  }

  return result
}
