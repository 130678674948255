import { React, AppForms, Settings, variantProvider } from '@/app'
import { AppStatus, ModalName, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { assignTextStyle, useForm, useI18N, useState } from '@codeleap/common'
import { Button, Checkbox, Text, TextInput, View, AuthFormComponents, Link as LinkCP } from '@/components'
import { navigate } from 'gatsby'
import { useKeydown } from '@/utils'

type SignupFormProps = {
  isModal?: boolean
  title?: string
  modalName?: ModalName
}

const BREAKPOINT = 'mid'

export const SignupForm = ({ isModal = true, title = null, modalName = 'signup' }: SignupFormProps) => {
  const isFocused = useAppSelector(state => state?.AppStatus?.modals?.signup)
  const [signUpSource, setSignUpSource] = useState('email')
  const areFieldsVisible = isModal ? isFocused : true
  const formConfig = signUpSource === 'email' ? AppForms.signup(areFieldsVisible) : AppForms.socialSignup(areFieldsVisible)

  const form = useForm(formConfig)

  const handleSocial = APIClient.Session.useSocialLogin((userSocial, provider) => {
    if (userSocial) {
      AppStatus.set('loading')

      form.setFormValues(userSocial)
      setSignUpSource(provider)

      setTimeout(() => {
        handleSubmit(true, userSocial)
      }, 1000)
    }
  }, modalName)

  const { t } = useI18N()

  const { signup } = APIClient.Session.useSignup()

  const isSocial = signUpSource !== 'email'

  const handleSubmit = async (withSocial = false, userValues = null) => {
    const { name, email, ...rest } = (userValues || form.values)
    const firstName = name.split(' ').slice(0, 1).join(' ')
    const lastName = name.split(' ').slice(1).join(' ')
    const values = { ...rest, email: email.toLowerCase(), first_name: firstName, last_name: lastName }

    const result = await signup(values, signUpSource as any)

    if (!!result?.user) {
      if (withSocial) {
        navigate('/auth/aboutYou/')
        AppStatus.set('done')
        return
      }

      if (isModal) {
        AppStatus.transitionModals([
          modalName,
          'hereWeGo',
        ])
      } else {
        AppStatus.setModal('hereWeGo')
      }
    }
  }

  const openLogin = () => {
    if (isModal) {
      AppStatus.transitionModals([modalName, 'login'])
    } else {
      navigate('/auth/login/')
    }
  }

  useKeydown(() => {
    const isInvalidSubmit = isModal && !isFocused || !form.isValid
    if (isInvalidSubmit) return
    handleSubmit?.()
  }, [isFocused, isModal, form?.values], 'enter')

  return (
    <>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={title || t('SignUp.title')}
      />
      <Text variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}>
        {t('SignUp.txt1') + ' '}
        {t('SignUp.txt2')} <LinkCP style={styles.privacyLink} to={Settings.ContactINFO.TermsAndPrivacy}>{t('SignUp.privacyPolicy')}</LinkCP> {t('SignUp.txt3')}
      </Text>
      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'gap:1']}>
          <View variants={['row', 'gap:4']} responsiveVariants={{ [BREAKPOINT]: ['column', 'gap:0'] }} >
            <TextInput
              {...form.register('name')}
            />
            <TextInput
              {...form.register('email')}
              leftIcon={{ name: 'mail' }}
              disabled={isSocial}
            />
          </View>
          {
            isSocial ? null : (
              <View variants={['row', 'gap:4']} responsiveVariants={{ [BREAKPOINT]: ['column', 'gap:0'] }} >
                <TextInput
                  {...form.register('password')}
                  leftIcon={{ name: 'key' }}
                  visibilityToggle
                />
                <TextInput
                  {...form.register('repeatPassword')}
                  leftIcon={{ name: 'key' }}
                  visibilityToggle
                />
              </View>
            )
          }
          <View variants={['column', 'gap:2']}>
            <View>
              <Checkbox
                {...form.register('remember')}
                onValueChange={(val) => {
                  APIClient.Session.toggleRememberMe(val)
                  form.setFieldValue('remember', val)
                }}
                variants={['left']}
              />
            </View>
            <View>
              <Checkbox
                {...form.register('receive_marketing_email')}
                variants={['left']}
              />
            </View>
          </View>
        </View>

        <View variants={['alignSelfCenter']}>
          <Text variants={['p3', 'color:neutral8']} >
            {t('Login.disclaimer')}
            <LinkCP
              to='https://hurd.world/terms-of-use'
              text={t('termsOfUse') + ', '}
              style={styles.link}
              tabIndex={0}
            />
            <LinkCP
              to='https://hurd.world/privacy-policy'
              text={t('privacyPolicy')}
              style={styles.link}
              tabIndex={0}
            />
            {t('Login.disclaimer2')}
            <LinkCP
              to='https://hurd.world/community-guidelines'
              text={' ' + t('communityGuidelines')}
              style={styles.link}
              tabIndex={0}
            />
          </Text>
        </View>

        <AuthFormComponents.SocialProviders onPress={handleSocial} />

        <View variants={['column', 'gap:2', 'alignSelfCenter']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit Signup`}
            text={t('SignUp.createAccount')}
            variants={['marginHorizontal:auto', 'fullWidth', 'large', 'pill', 'paddingHorizontal:2', !form.isValid && 'outline']}
            aria-label={t('SignUp.createAccount')}
            aria-invalid={!form.isValid}
          />
          <Button
            aria-label={`${t('SignUp.txt4')} ${t('SignUp.login')}`}
            onPress={openLogin}
            debugName={'Already have account Button'}
            variants={['outline', 'pill', 'large', 'row', 'gap:0.5', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}
          >
            <Text text={t('SignUp.txt4')} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
            <Text text={t('SignUp.login')} variants={['medium', 'color:primary3', 'inline', 'marginRight:0.5']} />
          </Button>
        </View>
      </View>
    </>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  privacyLink: {
    display: 'inline-block',
    textDecoration: 'none',
    fontWeight: '700',
    color: theme.colors.primary3,
  },
  link: {
    ...assignTextStyle('p3')(theme).text,
    color: theme.colors.primary3,
    fontWeight: '400',
    textDecoration: 'none',
  },

}), true)
