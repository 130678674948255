import { Reviews, SuccessStories } from '../Organisations'
import { ScoresContext } from './context'

export const ScoreStories = () => {
  const params = ScoresContext.useScoresContext(c => c.params)

  const isStoriesTab = ScoresContext.useScoresContext(c => c.params?.tab === ScoresContext.storiesTab.value)

  const setParams = ScoresContext.useScoresContext(c => c.setParams)

  if (!isStoriesTab) return null

  return <SuccessStories
    key='stories'
    params={params}
    setParams={setParams}
  />
}
