import { AppStatus, ModalName } from '@/redux'
import { APIClient } from '@/services'
import { TypeGuards, onUpdate } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

type UseHandleOpenParams = {
  mapping: {
    [queryParam: string]: ModalName
  }
  onOpen?: () => void
}

export const useHandleOpen = (options: UseHandleOpenParams) => {
  const {
    mapping,
    onOpen,
  } = options

  const initialParams = Object.fromEntries(Object.keys(mapping).map(key => [key, undefined]))
  const [params, setParams] = useSearchParams(initialParams)

  const { awaitAuth } = APIClient.Session.useAuthState()

  const handleOpen = async (modal: ModalName) => {
    try {
      const { state } = await awaitAuth()

      setParams(initialParams)

      if (state === 'logged_in') {
        if (!modal && TypeGuards.isFunction(onOpen)) {
          onOpen()
        }

        if (!!modal) {
          AppStatus.setModal([modal, true])
        }
        return
      }

      const nextUrl =
        window.location.pathname + encodeURIComponent(window.location.search)

      navigate('/auth/login/' + `?next=${nextUrl}`)
    } catch (e) {
      logger.error('Error with useHandleOpen', e)
    }
  }

  const { search } = useLocation()

  onUpdate(() => {
    const controllerName = Object.keys(mapping).find(controller => {
      return queryParamTrue(controller, params, search)
    })

    if (controllerName) {
      handleOpen(mapping[controllerName]).finally(() => {
        setParams(initialParams)
      })
    }
  }, [...Object.values(params), search])
}

function queryParamTrue(controller, params, search) {
  const shouldOpen = params[controller] === 'true'
  const locationParams = new URLSearchParams(search)

  const shouldOpenFromUrl = locationParams.get(controller) === 'true'

  return shouldOpen || shouldOpenFromUrl
}
