import { createSlice } from '@codeleap/common'

export type SessionState = {
  firebaseUserClaims: {
    [key: string]: any
  }
  onboardingQuestionsWasAppeared: boolean
  abortedAction: 'review' | 'stories'
  hasLoggedOut: boolean
}

const initialState: SessionState = {
  firebaseUserClaims: {},
  onboardingQuestionsWasAppeared: false,
  abortedAction: null,
  hasLoggedOut: false,
}

export const sessionSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {
    setUserClaims: (state, firebaseUserClaims: SessionState['firebaseUserClaims']) => ({
      ...state,
      firebaseUserClaims,
    }),
    setOnboardingQuestionsWasAppeared: (state, setTo: boolean) => ({
      ...state,
      onboardingQuestionsWasAppeared: setTo,
    }),
    setAbortedAction: (state, abortedAction: SessionState['abortedAction']) => ({
      ...state,
      abortedAction,
    }),
    setHasLoggedOut: (state, hasLoggedOut: boolean) => ({
      ...state,
      hasLoggedOut,
    }),
  },
  asyncReducers: {},
})
