import { View, GradientModal, Text } from '@/components'
import { shareOrganisation } from '@/utils'
import { ClimateEngagementScore } from '../../'
import { ReviewResultsCard, ReviewResultsHeader } from '../Cards'
import { APIClient } from '@/services'
import { useAppI18N, variantProvider, React, AppImages, Theme } from '@/app'
import { AnyFunction } from '@codeleap/common'
import { Organisation } from '@/types'

type ScoreModalProps = {
  visible: boolean
  toggle: AnyFunction
  organisation?: Organisation
  score?: Organisation['score']
}

export const ScoreModal = (props: ScoreModalProps) => {
  const { visible, toggle, organisation, score = organisation?.score } = props
  const { categories } = APIClient.Organisations.useCategories()
  const { t } = useAppI18N()

  if (!score || !categories) return null
  const categoriesArr = Object.values(categories)

  const hasNps = !!organisation?.nps

  return (
    <GradientModal
      toggle={() => toggle(!visible)}
      visible={visible}
      title={t('organisations.climateEmpowermentScore')}
      onSharePress={organisation ? () => shareOrganisation(organisation) : undefined}
      debugName={'GradientScoreModal'}
      bodyStyle={styles.body}
      boxStyle={styles.box}
      headerStyle={styles.header}
    >
      <>
        <Text variants={['p1']} text={t('organisations.climateEmpowermentScoreDescription')} />
        <View style={styles.wrapper}>
          <View variants={['column', 'gap:2', 'center', 'fullWidth', 'marginBottom:4']} responsiveVariants={{ small: ['marginBottom:2'] }}>
            <ClimateEngagementScore variant={'withoutModal'} score={score} />
          </View>
          {categoriesArr.map((item) => (
            <ReviewResultsCard key={item.id} category={item} score={score.categories[item.id]} />
          ))}
          {/*
          {hasNps ? (
            <View style={[Theme.effects.sectionElevation]} variants={['bg:neutral1', 'fullWidth', 'padding:2', 'border-radius:small', 'fullWidth', 'column']}>
              <ReviewResultsHeader
                score={organisation?.nps?.score}
                img={AppImages.ClimateNPS}
                description={t('climateNPS.description')}
                name={t('climateNPS.title')}
              />
              <Text
                variants={['p4', 'alignSelfCenter', 'marginTop:1']}
                text={t('climateNPS.responses', { qtd: organisation?.nps?.number_of_contributors })}
              />
            </View>
          ) : null} */}
        </View>
      </>
    </GradientModal>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  body: {
    overflow: 'auto',
    maxHeight: `calc(100dvh - 56px)`,

    [theme.media.up('small')]: {
      padding: theme.spacing.value(4),
      paddingTop: theme.spacing.value(0),
    },
  },
  header: {
    [theme.media.up('small')]: {
      paddingLeft: theme.spacing.value(4),
      paddingRight: theme.spacing.value(4),
    },
  },
  box: {
    [theme.media.up('small')]: {
      padding: theme.spacing.value(0),
      paddingTop: theme.spacing.value(4),
    },
  },
  wrapper: {
    ...theme.presets.column,
    ...theme.spacing.gap(2),
    ...theme.spacing.marginTop(3),
    maxWidth: 'calc(100vw - 32px)',
  },
}), true)
