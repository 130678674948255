import { createTheme, TypeGuards, validateTheme, VariantProvider } from '@codeleap/common'
import { textStyles } from './textStyles'
import { Icons } from './assets/icons'
import { logger } from './logger'
import { CSSObject } from '@emotion/react'
import { effects } from './effects'

const themeSpacing = 8

const colors = {
  primary1: '#E8F7F0',
  primary2: '#9FDCC2',
  primary3: '#14AA69',
  primary4: '#0E774A',
  primary5: '#0C6840',
  neutral1: '#FFFFFF',
  neutral2: '#F6F5F5',
  neutral3: '#E0DFDF',
  neutral4: '#C4C2C2',
  neutral5: '#807B7A',
  neutral6: '#575150',
  neutral7: '#3D3635',
  neutral8: '#2D2524',
  neutral9: '#130A09',
  neutral10: '#000000',
  positive1: '#DBF3E8',
  positive2: '#42C586',
  warning1: '#F5F0D1',
  warning2: '#E4C000',
  alert1: '#F3DBDB',
  alert2: '#F33F3F',
  destructive1: '#F3DBDB',
  destructive2: '#F33F3F',
  background: '#FFFFFF',
  separator: '#E5E5E5AA',
  border: '#CCCCCC',
  overlay: '#000000AA',
  headlines: '#130A09',
  body: '#575150',
  caption: '#807B7A',
  ripple: '#0002',
  transparent: '#FFF0',

  labelButton: '#130A09',
  bgImage: '#E8F7F0',
  orange1: '#FFDEB6',
  orange2: '#FF9E26',
  orange3: '#E87613',
  yellow1: '#FFEFC5',
  yellow2: '#FFC226',
  yellow3: '#DD9E00',
  cyan1: '#B5E0F3',
  cyan2: '#38C3FF',
  cyan3: '#0098D3',
  acqua1: '#CDE4E7',
  acqua2: '#89C5CC',
  acqua3: '#68A1AC',
  lavender1: '#B4C6F9',
  lavender2: '#7E9DF4',
  lavender3: '#3363EB',
  purple1: '#D0CAFF',
  purple2: '#342A83',
  purple3: '#2A0752',
  skin1: '#FFCCAF',
  skin2: '#DFA056',
  skin3: '#B28B67',
  skin4: '#BE834D',
  skin5: '#945215',
  skin6: '#482300',

  'secondary1': '#E4E4F8',
  'secondary2': '#B3B4F8',
  'secondary3': '#8183F8',
  'secondary4': '#6668C4',
  'secondary5': '#4C4D91',
  'card': '#F2F2F2',
  'linkedIn': '#0073B1',
}

export const themeBreakpoints = {
  zero: 0,
  tinyest: 290,
  tiny: 350,
  smallish: 420,
  small: 600,
  mid: 1024,
  largeish: 1920,
  large: 2160,
  xlarge: 1400,
  xxlarge: 2440,
  huge: 2600,
}

export type ThemeBreakpoints = Partial<keyof typeof themeBreakpoints>

const themeObj = validateTheme({
  colors: { light: colors, dark: colors },
  initialTheme: 'light',
  spacing: themeSpacing,
  borderRadius: {
    tiny: 8,
    small: 16,
    medium: 24,
    large: 32,
    rounded: 999999,
  },
  typography: {
    base: {
      fontFamily: 'Karla',
      styles: textStyles,
    },
    quotes: {
      fontFamily: '',
      styles: textStyles,
    },
  },
  icons: Icons,
  presets: {
    debugger: function (color: 'blue' | 'red' | 'yellow' | 'green' | 'purple' = 'red', background = false) {
      const hex = color === 'purple' ? '#9400D3' : color

      return {
        borderWidth: 1,
        borderColor: hex,
        color: hex,
        ...(background ? { backgroundColor: hex } : {}),
      }
    },
    calcWidth: function (partial: number | string, total = '100vw') {
      const subtract = TypeGuards.isString(partial) ? partial : `${partial}px`
      return `calc(${total} - ${subtract})`
    },
  },
  effects,
  breakpoints: themeBreakpoints,
  values: {
    height: 10,
    width: 10,
    pixel: 1,
    innerSpacing: { X: 2, Y: 2, value: 16 },
    outerSpacing: { X: 2, Y: 2, value: 16 },
    gap: 2,
    smallGap: 1,
    itemHeight: {
      default: 48,
      small: 32,
      tiny: 20,
    },
    iconSize: {
      1: 16,
      2: 20,
      3: 24,
      4: 32,
      5: 48,
      6: 64,
    },
    headerHeight: 60,
    borderWidth: {
      small: 1,
      medium: 2,
    },
  },
})

const appTheme = createTheme(themeObj, {
  screenSize: () => [0, 0],
})

export type TCSS = CSSObject

const styleGetter = (
  style: TCSS,
) => {

  return style
}

type StyleGetter = typeof styleGetter

export const variantProvider = new VariantProvider<
  StyleGetter,
  typeof themeObj
>(appTheme, styleGetter, logger)

export const Theme = variantProvider.theme

export type AppThemeModes = keyof typeof themeObj.colors

export type Breakpoint = keyof typeof themeObj.breakpoints

export const INTERACTIVE_SPLASH_TRANSITION = 3500
