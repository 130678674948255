import React from 'react'
import { Theme, variantProvider } from '@/app'
import { Text, Page, View, SegmentedControl, SearchLabel, Grid, ModuleCard, LearnEmptyPlaceholder, FilterLearn, SearchLearn, AuthenticatedScreen, Redirect, LoadingScreen } from '@/components'
import { APIClient, useSharedSettings } from '@/services'
import { useIsMobile } from '@/utils'
import { onUpdate, useI18N } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import { useAppSelector } from '@/redux'

function Learn() {
  const [searchParams, setSearchParams] = useSearchParams({
    sources: '',
  })

  const modules = APIClient.Learn.useModules({ sources: searchParams.sources })
  const { isLoggedIn } = APIClient.Session.useSession()

  const isMobile = useIsMobile()

  const { t } = useI18N()

  onUpdate(() => {
    const hasFilteredSources = modules.filter.sources.length > 0
    if (hasFilteredSources || searchParams.sources !== '') {
      setSearchParams({
        ...searchParams,
        sources: modules.filter.sources.join(','),
      })
    }
  }, [modules.filter.sources])

  const renderModuleItem = React.useCallback(({ item }) => (
    <ModuleCard key={item?.id + 'learn-lesson'} module={item} tabIndex={0} />
  ), [])

  const spacing = React.useMemo(() => {
    return Theme.spacing.value(isMobile ? 3 : 2)
  }, [isMobile])

  const TabsLessons = React.useMemo(() => {
    return (
      <SegmentedControl
        debugName='Filter lessons'
        onValueChange={modules?.setStatus}
        value={modules?.status as any}
        options={modules?.moduleStatus as any}
        variants={['tab', 'marginTop:1', 'marginBottom:4', 'noBreakline']}
        responsiveVariants={{
          small: ['fullWidth', 'marginBottom:2'],
        }}
      />
    )
  }, [modules?.status])

  return (
    <>
      <Page
        centerContent={!isMobile}
        showSubHeader
        showFooter={false}
        variants={['section:elevated', 'header:learn']}
        subHeader={
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <Text text={t('learn.learnScreen.title')} variants={['h0', 'color:neutral1']} />

              {isMobile ? null : (
                <Text
                  text={t('learn.learnScreen.subtitle')}
                  variants={['p1', 'color:neutral1']}
                />
              )}
            </View>

            {isMobile ? (
              <View style={styles.filtersWrapper}>
                <View style={styles.filters}>
                  <FilterLearn modules={modules} />
                </View>
              </View>
            ) : null}
          </View>
        }
        pageTitle={t('learn.learnScreen.title')}
      >
        <View style={styles.page}>
          <View style={styles.content}>
            <View variants={['row', 'alignCenter', 'justifySpaceBetween', 'fullWidth']}>
              {TabsLessons}

              {isMobile ? null : (
                <View css={[styles.filtersLarge]}>
                  <FilterLearn modules={modules} />
                </View>
              )}
            </View>

            <SearchLabel search={modules.filter.search} />

            <Grid
              {...modules.modulesListProps}
              rowItemsSpacing={spacing}
              columnItemsSpacing={spacing}
              debugName='GridLearn:lessons'
              numColumns={isMobile ? 1 : 2}
              renderItem={renderModuleItem}
              placeholder={{
                ...modules?.modulesListProps.placeholder,
                renderEmpty: () => <LearnEmptyPlaceholder list='modules' />,
              }}
            />
          </View>
        </View>
      </Page>
    </>
  )
}

export default (props) => {
  const { isLoggedIn, loginResolved } = APIClient.Session.useSession()
  const hasLoggedOut = useAppSelector(s => s.Session.hasLoggedOut)
  const sharedSettings = useSharedSettings()


  if (!sharedSettings.isUpdated && !loginResolved) {
    return <LoadingScreen />
  }

  if (loginResolved && !isLoggedIn && !hasLoggedOut && !APIClient.Learn.initialLessonDone()) {
    return <Redirect to={`lessonView/${sharedSettings?.settings?.initial_lesson}/`} />
  }

  return (
    <AuthenticatedScreen>
      <Learn {...props} />
    </AuthenticatedScreen>
  )
}

const HEADER_WIDTH = 500
const TAG_HEIGHT = 32

const styles = variantProvider.createComponentStyle(theme => ({
  page: {
    minHeight: '80vh',
    ...theme.presets.column,
    ...theme.presets.alignStart,
    ...theme.presets.justifyStart,
    ...theme.spacing.padding(2),

    [theme.media.down('small')]: {
      ...theme.spacing.padding(0),
      ...theme.spacing.paddingTop(1),
      ...theme.presets.fullWidth,
      ...theme.presets.alignCenter,
    },
  },
  content: {
    ...theme.presets.fullWidth,
    ...theme.presets.column,
    ...theme.presets.alignStart,
    ...theme.presets.justifyStart,

    [theme.media.down('small')]: {
      width: theme.presets.calcWidth(theme.spacing.value(4)),
      maxWidth: theme.presets.calcWidth(theme.spacing.value(4)),
      ...theme.presets.alignCenter,
    },
  },
  header: {
    maxWidth: HEADER_WIDTH,
    ...theme.presets.column,
    ...theme.spacing.gap(3),

    [theme.media.down('small')]: {
      maxWidth: '100vw',
      ...theme.presets.center,
      ...theme.spacing.gap(1),
    },
  },
  headerContent: {
    ...theme.presets.column,
    ...theme.spacing.gap(3),

    [theme.media.down('small')]: {
      ...theme.spacing.gap(1),
      width: theme.presets.calcWidth(theme.spacing.value(4)),
    },
  },
  filtersWrapper: {
    width: '100vw',
    overflow: 'visible',
    position: 'relative',
    height: TAG_HEIGHT + theme.spacing.value(1.5),
    overflowX: 'scroll',
    overflowY: 'hidden',
    marginTop: theme.spacing.value(1),

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    overflow: 'visible',
    position: 'absolute',
    left: 0,
    top: 0,
    marginLeft: theme.spacing.value(2),
    marginRight: theme.spacing.value(2),
    ...theme.spacing.gap(1),
  },
  filtersLarge: {
    height: TAG_HEIGHT,
    maxHeight: TAG_HEIGHT,
    overflow: 'hidden',
    ...theme.spacing.gap(1),
  },
  filtersLargeStories: {
    marginLeft: 'auto',
  },
  masonry: {
    ...theme.spacing.marginTop(4),
  },
  invisible: {
    opacity: '0',
    pointerEvents: 'none',

    [theme.media.down('small')]: {
      display: 'none',
    },
  },
}), true)

