import React from 'react'
import { AppStatus, ModalName, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { navigate } from 'gatsby'
import { Alert } from '@/components'
import { onUpdate, useI18N } from '@codeleap/common'
import { useLocation } from '@reach/router'
import { ReauthenticationStore } from '@/utils'

const authValidators = [
  'login',
  'signup',
  'aboutJob',
  'aboutYou',
  'verifyEmailAfter',
  'verifyEmailBefore',
  'success',
  'onboarding',
  'loading',
  'done',
]

export const ProfileRedirectModal = () => {
  const authModelOpen = useAppSelector(state => {
    const modals = state?.AppStatus?.modals
    const open = Object?.keys(modals)?.find(modal => authValidators.includes(modal) && modals?.[modal] === true)
    return !!open
  })

  const status = useAppSelector(state => state?.AppStatus?.status)
  const visible = useAppSelector(state => state?.AppStatus?.modals?.profileRedirect)

  const { profile, logout, isLoggedIn } = APIClient.Session.useSession()

  const location = useLocation()
  const { t } = useI18N()

  const redirect = async () => {
    const { isReauthentication } = ReauthenticationStore.get()
    const { nextStep, modal } = await APIClient.Session.checkSignupCompletion(profile)

    if (!!modal) {
      if (isReauthentication) {
        navigate('/learn/')
      } else {
        navigate('/auth/signup/')
      }

      AppStatus.transitionModals(['profileRedirect', modal as ModalName])
      return
    }

    navigate(`/auth/${nextStep}`)
    AppStatus.setModal('profileRedirect')
  }

  const dismiss = async () => {
    await logout()
    AppStatus.setModal('profileRedirect')
  }

  // onUpdate(() => {
  //   if (!!isLoggedIn) {
  //     const checkProfile = async () => {
  //       AppStatus.setModal(['profileRedirect', false])

  //       const { isComplete } = await APIClient.Session.checkSignupCompletion(profile)

  //       const isAuthRoute = authValidators?.find(route => location?.pathname?.includes?.(route))

  //       if (!isComplete && !isAuthRoute) {
  //         AppStatus.setModal(['profileRedirect', true])
  //       }
  //     }

  //     checkProfile()
  //   }
  // }, [location?.pathname, isLoggedIn, profile, authModelOpen])

  return (
    <Alert
      visible={visible && isLoggedIn && !authModelOpen && !(status === 'loading' || status === 'done')}
      title={t('profileRedirectModal.title')}
      body={t('profileRedirectModal.body')}
      type={'warn'}
      toggle={() => null}
      closable={false}
      debugName={'Profile redirect modal'}
      onAction={redirect}
      onDismiss={dismiss}
      actionButtonProps={{
        text: t('profileRedirectModal.continue'),
        debugName: 'Profile edit check password change email alert modal - done on press',
        variants: ['flex', 'large', 'pill', 'paddingHorizontal:2'],
      }}
      dismissButtonProps={{
        text: t('profileRedirectModal.dismiss'),
        debugName: 'Profile edit check password change email alert modal - done on press',
        variants: ['flex', 'outline', 'large', 'pill', 'paddingHorizontal:2'],
      }}
    />
  )
}
