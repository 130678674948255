import React from 'react'
import { View, Button, Image, Modal, Text, ActivityIndicator } from '@/components'
import { AppImages } from '@/app'
import { AppStatus, useAppSelector } from '@/redux'
import { APIClient } from '@/services'
import { Organisation } from '@/types'
import { PropsOf } from '@codeleap/common'

type DiscoverScoreProps = { organisation: Organisation }

const toggle = () => {
  AppStatus.setModal(['discoverScore', false])
}

const Wrapper = (props: React.PropsWithChildren) => <View variants={['column', 'gap:2', 'alignCenter']}>{props.children}</View>

const DiscoverScoreContent = (props: DiscoverScoreProps) => {

  const { organisation } = props

  const contactUs = APIClient.Organisations.useContact(organisation)

  const onDismiss = () => {
    toggle()
  }

  const onContactUs = () => {
    contactUs.contact()
  }

  if (contactUs.isLoading) {
    return <Wrapper>
      <ActivityIndicator debugName='Contact us loading' />
    </Wrapper>
  }

  if (contactUs.isSuccess) {
    return <Wrapper>
      <Image source={AppImages.DiscoverScoreDone}/>
      <Text text={'Want to find out your organisation’s score?'} />
      <Button text={'Dismiss'} debugName='Dismiss Discover score' onPress={onDismiss} variants={['fullWidth']}/>
    </Wrapper>
  }

  return <Wrapper>
    <Text text={'Want to find out your organisation’s score?'} />
    <View variants={['fullWidth', 'gap:2']}>
      <Button text={'Dismiss'} debugName='Dismiss Discover score' onPress={onDismiss} variants={['flex', 'outline']}/>
      <Button text={'Contact us'} debugName='Contact us' onPress={onContactUs} variants={['flex']}/>
    </View>
  </Wrapper>

}

export function DiscoverScoreModal(props: DiscoverScoreProps) {
  const { organisation } = props

  const visible = useAppSelector(store => store.AppStatus.modals.discoverScore)

  return <Modal title='Discover Score' visible={visible} toggle={toggle} variants={['centered', 'boxPadding:4', 'stretched']} closable={false} >
    <DiscoverScoreContent organisation={organisation}/>
  </Modal>
}

DiscoverScoreModal.Button = (props: Partial<PropsOf<typeof Button>>) => {
  return <Button debugName='Learn about score' text={'Want to see more?'} onPress={() => AppStatus.setModal('discoverScore')} {...props} />
}
