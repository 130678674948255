import React from 'react'
import { assignTextStyle, Theme, useAppI18N, variantProvider } from '@/app'
import { Button, Icon, Modal, LearnOption, Text, Touchable, View, ActionIcon } from '@/components'
import { onUpdate, TypeGuards, useBooleanToggle } from '@codeleap/common'
import { authWall, useIsMobile } from '@/utils'
import { SectionProps, Sections } from './Learn/Filter/Sections'

type FilterModalProps = {
  sections: SectionProps[] | SectionProps
  clearFilters?: boolean
  showBadge: boolean
  hideClearOption: boolean
}

export const FilterModal = (props: FilterModalProps) => {
  const { clearFilters, sections: _sections, showBadge, hideClearOption } = props

  const sections: SectionProps[] = React.useMemo(() => TypeGuards.isArray(_sections) ? _sections : [_sections], [_sections])
  const filterName = React.useMemo(() => sections[0].label, [sections])

  const getSectionFilters = React.useCallback(() => {
    return sections?.reduce((acc, section) => ({ ...acc, [section?.key]: section?.state }), {})
  }, [sections])

  const setSectionFilters = React.useCallback((values) => {
    sections?.forEach(section => {
      const sectionValues = TypeGuards.isArray(values?.[section?.key])
        ? values[section.key].map(s => s.value)
        : values?.[section.key] ?? []

      section?.setter(sectionValues)
    })
  }, [sections])

  const { t } = useAppI18N()
  const isMobile = useIsMobile()
  const [visible, toggle] = useBooleanToggle(false)
  const [filter, setFilter] = React.useState(getSectionFilters)

  const handleFilter = (item, sectionKey) => {
    const isMultiple = sections.find(s => s.key === sectionKey).multiple
    if (!isMultiple) setFilter((prev) => ({ ...prev, [sectionKey]: item.value }))
    else {
      const sectionFilter = filter?.[sectionKey] ?? []
      const isSelected = sectionFilter.some(f => f.value === item.value)
      const newFilters = isSelected ? sectionFilter.filter(f => f.value !== item.value) : [...sectionFilter, item]

      setFilter({ ...filter, [sectionKey]: newFilters })
    }
  }

  const badge = React.useMemo(() => showBadge ? Object.values(filter)[0]?.[0]?.label || '' : '', [filter, showBadge])

  const onCloseFilters = React.useCallback(() => {
    const _filters = getSectionFilters()
    setFilter(_filters)
    toggle(false)
  }, [])

  const handleClearFilters = React.useCallback(() => {
    setSectionFilters({})
    setFilter({})
    toggle(false)
  }, [])

  const handleApplyFilters = React.useCallback(() => {
    setSectionFilters(filter)
    toggle()
  }, [filter])

  onUpdate(() => {
    if (clearFilters) handleClearFilters()
  }, [clearFilters])

  const SectionItem = React.useCallback(({ item, isFirst, sectionKey }) => {
    const sectionFilter = filter[sectionKey]
    const isSelected = TypeGuards.isArray(sectionFilter) ? sectionFilter?.find(s => s.value === item.value) : sectionFilter === item.value

    return (
      <LearnOption
        spacingTop={!isFirst}
        key={'filter-modal-' + item?.id}
        text={item?.tag || item?.label}
        onPress={() => handleFilter(item, sectionKey)}
        selected={!!isSelected}
      />
    )
  }, [filter])

  return (
    <>
      <Touchable
        style={[styles.item, badge && styles.itemSelected]}
        onPress={authWall(toggle, true)}
        debugName='open filter Modules'
        aria-label={t('learn.components.filterModal.buttonActionText')}
        tabIndex={0}
      >
        <Text variants={['p3', 'color:neutral9', 'marginRight:0.5']} css={{ whiteSpace: 'nowrap' }} text={filterName} />
        {badge ? <View style={styles.badge}><Text css={styles.textBadge} text={badge} /></View> : null}
        <Icon debugName='q' name='chevron-down' variants={['neutral5', 'iconSize:2']} />
      </Touchable>

      <Modal
        visible={visible}
        toggle={toggle}
        onClose={onCloseFilters}
        debugName={`Modal filter lessons`}
        title={null}
        variants={[isMobile ? 'fullscreen' : 'centered', 'center']}
        showClose={false}
        scroll={false}
        styles={{
          'body': styles.body,
          'box': styles.box,
        }}
      >
        <View variants={['fullWidth', 'row', 'center', 'justifySpaceBetween', 'marginBottom:3']}>
          <Text variants={['h1']} text={filterName} />

          <ActionIcon
            name='x'
            onPress={onCloseFilters}
            debugName='close modal'
            variants={['minimal', 'iconSize:3', 'neutral5']}
          />
        </View>

        <View style={styles.scroll}>
          <Sections
            vertical
            sections={sections as any}
            SectionHeaderComponent={({ section, isFirst }) => (
              <Text variants={['p2', 'color:neutral5', 'marginBottom:1', (!isFirst) && 'marginTop:4']} text={section?.title} />
            )}
            SectionSeparatorComponent={() => <View variants={['marginTop:0.5']} />}
            renderItem={({ item, section, index, isFirst }) => (
              <SectionItem isFirst={isFirst} item={item} key={section?.key + index} sectionKey={section?.key}/>
            )}
          />
        </View>

        <View
          variants={['row', 'justifySpaceBetween', 'alignEnd', 'paddingVertical:4', 'gap:2', 'fullWidth', 'flex']}
          responsiveVariants={{ small: ['paddingVertical:2'] }}
        >
          {hideClearOption ? null : <Button
            variants={['flex', 'outline', 'border-radius:rounded']}
            text={t('learn.components.filterModal.buttonClear')}
            debugName={`Modal filter clear`}
            onPress={handleClearFilters}
          />}

          <Button
            variants={['flex', 'border-radius:rounded']}
            text={t('learn.components.filterModal.buttonSubmit')}
            debugName={`Modal filter apply`}
            onPress={handleApplyFilters}
          />
        </View>
      </Modal>
    </>
  )
}

const ITEM_HEIGHT = 32
const MODAL_WIDTH = 400 + Theme.spacing.value(4)

const styles = variantProvider.createComponentStyle(theme => ({
  item: {
    ...theme.presets.row,
    ...theme.presets.center,
    ...theme.presets.justifySpaceBetween,
    ...theme.spacing.padding(1),
    borderRadius: theme.borderRadius.tiny,
    backgroundColor: theme.colors.neutral2,
    height: ITEM_HEIGHT,
    maxWidth: ITEM_HEIGHT * 7,
  },
  itemSelected: {
    backgroundColor: theme.colors.primary1,
  },
  tagIndicator: {
    backgroundColor: theme.colors.neutral2,
    height: ITEM_HEIGHT,
    width: ITEM_HEIGHT,
    borderRadius: theme.borderRadius.tiny,
    ...theme.presets.center,
  },
  scroll: {
    ...theme.spacing.paddingBottom(0),
    ...theme.presets.column,
    ...theme.presets.fullWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  body: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',

    padding: theme.spacing.value(4),
    paddingBottom: theme.spacing.value(0),

    [theme.media.up('small')]: {
      width: '100vw',
      maxWidth: MODAL_WIDTH,
      maxHeight: '85svh',
    },

    [theme.media.down('small')]: {
      maxHeight: '100svh',
      flex: 1,
      padding: theme.spacing.value(2),
      paddingBottom: theme.spacing.value(0),
    },
  },
  box: {
    padding: theme.spacing.value(0),
  },
  badge: {
    backgroundColor: theme.colors.positive2,
    borderRadius: theme.borderRadius.rounded,
    ...theme.presets.center,
    ...theme.spacing.paddingHorizontal(0.5),
    maxWidth: theme.spacing.value(8),
    height: theme.values.iconSize[1],
  },
  textBadge: {
    ...assignTextStyle('p5')(theme).text,
    color: theme.colors.neutral1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: theme.spacing.value(8),
  },
  separator: {
    width: theme.spacing.value(1),
  },
}), true)
