import { AppImages, useAppI18N, variantProvider } from "@/app"
import { Image, Text, View } from "@/components"
import { FormTypes, PropsOf, TypeGuards } from "@codeleap/common"

type GenericEmptyPlaceholderProps = {
  image?: FormTypes.AnyFile
  title: string
  description: string
  descriptionVariants?: PropsOf<typeof Text>['variants']
  wrapperVariants?: PropsOf<typeof View>['variants']
}

 


export const NoReviewsPlaceholder = () => {
  const { t } = useAppI18N()

  return <View variants={['alignCenter', 'paddingHorizontal:2', 'paddingTop:2']}>
    <Image source={AppImages.noReviewsYet} style={styles.image}/>
    <Text text={t('organisations.noReviewsYet.title')} variants={['marginBottom:1', 'marginTop:2', 'h4', 'textCenter']}/>
    <Text text={t('organisations.noReviewsYet.description')} variants={['p1', 'textCenter']}/>
  </View>
}

const styles = variantProvider.createComponentStyle((theme) => ({
  image: {
    width: '35%',

    ...theme.spacing.marginBottom(4),
  },
}), true)
