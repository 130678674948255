import { AppImages, variantProvider } from '@/app'
import { authWall, shareReview, useAuthWall, useAuthLimitedFlatlistProps, useIsMobile, useFlatlistProps, useModal } from '@/utils'

import { onMount, onUpdate, useBooleanToggle, useCallback, useI18N } from '@codeleap/common'
import { View, Text, List, Tooltip, ReviewCard, Button, Icon, SearchInput, Image, DiscoverScoreModal } from '@/components'
import { ReviewCardModal } from './components'
import { APIClient } from '@/services'
import { Answer, Organisation } from '@/types'
import { StoriesCard } from '../Cards/Stories'
import { SORT_BY } from '@/services/api/stories'
import { createRef } from 'react'
import { AppStatus, shareStoryId, useAppSelector } from '@/redux'
import { analytics } from '@/services'

const reviewOpenedInitially = createRef(false)

const TIMEOUT_REVIEWS_REFRESH = 2000
let debounceTimer

type ReviewsProps = {
  organisation?: Organisation
  params?: APIClient.Answers.AnswerFilters
  setParams: React.Dispatch<React.SetStateAction<APIClient.Answers.AnswerFilters>>
  blurred?: boolean
}

export const Reviews = (props: ReviewsProps) => {
  const { organisation, blurred, params, setParams } = props

  const pulseQuestionOpen = useAppSelector(store => store?.Pulse?.successOpen)
  const [modalReviewId, setModalReview] = [params?.reviewId, (to) => setParams({ reviewId: to })]
  const detailsModal = useModal()

  const { t } = useI18N()

  if (blurred) {
    return <View variants={['fullWidth', 'column', 'gap:2', 'relative']}>
      <Image source={AppImages.ReviewsBlurred} style={styles.blurImage}/>
      <DiscoverScoreModal.Button style={styles.blurButton}/>
    </View>
  }

  const { answers: reviews } = APIClient.Answers.useAnswers({
    organisation: organisation?.id,
    content: params?.content,
    category: params?.category,
  })

  onUpdate(() => {
    if (pulseQuestionOpen) {
      setTimeout(() => {
        reviews?.list.refresh()
      }, TIMEOUT_REVIEWS_REFRESH)
    }
  }, [pulseQuestionOpen])

  onMount(() => {
    if (!!params?.reviewId && !reviewOpenedInitially.current) {
      authWall(() => {
        detailsModal.toggle()
        reviews.refreshItem(params.reviewId)

      })()
      reviewOpenedInitially.current = true
    }
  })

  const shouldToggleAuthWall = useAuthWall()

  const listProps = useAuthLimitedFlatlistProps(reviews, {
    noMoreItemsText: t('organisations.noMoreReviews'),
  })

  const wrapper = listProps?.data?.length ? 'h:auto' : 'fullHeight'

  const searchChange = useCallback((content) => {
    setParams({ content })

    if (content.trim()) {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(() => {
        analytics.track('review_search', { terms: content })
      }, 2000)
    }
  }, [])
  const Header = useCallback(() => (
    <SearchInput
      debugName='search:org'
      placeholder={t('organisations.searchInputPlaceholder.search')}
      variants={['noError']}
      onSearchChange={searchChange}
      defaultValue={params.content}
      onFocus={shouldToggleAuthWall ? authWall() : null}
      contentEditable={!shouldToggleAuthWall}
      debounce={500}
    />
  ), [])

  const onPressCard = useCallback((item: Answer) => {
    return () => {
      analytics.track('review_read_more')
      setModalReview(item.id)
      detailsModal.toggle()
    }
  }, [modalReviewId])

  const Placeholder = () => (
    <View variants={['column', 'gap:1', 'fullWidth', 'alignCenter', 'justifyCenter', 'textCenter']}>
      <Text variants={['h4', 'color:neutral9']}>
        {t('organisations.reviewsPlaceholder.title')}
      </Text>
      <Text variants={['p1', 'color:neutral6']}>
        {t('organisations.reviewsPlaceholder.description')}
      </Text>
    </View>
  )

  const renderItem = useCallback(({ item, index }: { item: Answer }) => {
    return <ReviewCard
      progress={item?.value}
      date={item?.created_datetime}
      title={item?.question?.comment}
      text={item?.comment}
      replies={item?.replies}
      question={item?.question}
      icon={item?.question?.sub_category?.web_icon || item?.question?.sub_category?.mobile_icon}
      onPress={authWall(onPressCard(item))}
      tabIndex={index}
    />
  }, [])

  const modalReview = reviews.itemMap[modalReviewId]

  return (
    <View variants={[wrapper]}>

      <View variants={['column', 'gap:2', 'fullWidth']}>
        <Header />
        <List
          {...listProps}
          styles={{ innerWrapper: styles.innerWrapper }}
          separators
          rowItemsSpacing={4}
          variants={['hiddenSeparator']}
          renderItem={renderItem}
          placeholder={{
            ...listProps?.placeholder,
            renderEmpty: Placeholder,
          }}
        />
      </View>

      <ReviewCardModal
        onClose={() => {
          setModalReview(null)
        }}
        visible={detailsModal.visible}
        toggle={() => {
          detailsModal.toggle()
          setModalReview(null)
        }}
        review={modalReview}
        onShare={organisation ? () => shareReview(organisation, modalReview) : null}

      />
    </View>
  )
}

const Sorting = ({ value, onValueChange, options }) => {

  const onPress = (item) => {
    onValueChange(APIClient.Stories.SORT_BY[item.value])
    analytics.track(`story_filter_${item.value === 'likes' ? 'likes' : 'date'}`)
  }

  return <View variants={['gap:1', 'column']}>
    {
      options.map((item) => {
        const selected = value === item.value
        return <Button
          debugName={`SortingButton ${item.value}`}
          key={item.value}
          onPress={() => onPress(item)}
          text={item.label}
          selected={selected}
          rightIcon={selected ? 'check' : null}
          variants={['tooltipMenu', 'fullWidth']}
        />
      })
    }
  </View>
}

export const SuccessStories = ({ organisation, params, setParams }) => {
  const { t } = useI18N()

  const [sortBy, setSortBy] = [params?.setStoriesBy, (to) => setParams({ setStoriesBy: to })]

  const [sortOpen, toggleSort] = useBooleanToggle(false)

  const shouldToggleAuthWall = useAuthWall()
  const isMobile = useIsMobile()

  const tooltipSide = isMobile ? 'left' : 'bottom'

  const { stories } = APIClient.Stories.useStories({

    organisation: organisation?.id,
    sort_by: sortBy,
    category: params?.category,
    search: params?.storyTitle,

  })

  const sortingOptions = [
    {
      label: t('organisations.stories.sortLikes'),
      value: SORT_BY.likes,
    },
    {
      label: t('organisations.stories.sortDate'),
      value: SORT_BY.created_datetime,
    },
  ]

  const listProps = useAuthLimitedFlatlistProps(stories, {})

  const handlePressStoryCard = (story) => {
    analytics.track('story_view', { source: 'Organisation' })
    setParams({ 'storyId': story?.id, 'section': 'stories' })
    AppStatus.setModal('storyDetail')
    shareStoryId.setState({ id: story?.id })
  }

  const Search = useCallback(() => (
    <SearchInput
      placeholder={t('organisations.searchInputPlaceholder.search')}
      variants={['noError']}
      onSearchChange={val => setParams({ storyTitle: val })}
      defaultValue={params.storyTitle}
      debugName='SuccessStoriesSearchInput'
      onFocus={shouldToggleAuthWall ? authWall() : null}
      contentEditable={!shouldToggleAuthWall}
      debounce={500}
    />
  ), [sortBy, shouldToggleAuthWall])

  const onLike = (id) => stories.actions.toggleLike(id)

  const renderItem = useCallback(({ item }) => {
    const onPress = (item) => {
      analytics.track('case_read_more')
      handlePressStoryCard(item)
    }

    return <StoriesCard
      story={item}
      onToggleLike={authWall(() => onLike(item.id))}
      onPress={authWall(() => onPress(item))}
      showOrganisation={false}
      variants={['fullWidth']}
      replies={item.replies}
    />
  }, [])

  const Placeholder = () => (
    <View variants={['column', 'gap:1', 'fullWidth', 'alignCenter', 'justifyCenter', 'textCenter']}>
      <Text variants={['h4', 'color:neutral9']}>
        {t('organisations.storiesPlaceholder.title')}
      </Text>
      <Text variants={['p1', 'color:neutral6']}>
        {t('organisations.storiesPlaceholder.description')}
      </Text>
    </View>
  )

  return (
    <View variants={['column', 'gap:2', 'fullWidth']}>
      <View variants={['fullWidth', 'gap:2', 'alignCenter']}>
        <Search />
        <Tooltip
          debugName={'successStoriesTooltip'}
          content={<Sorting options={sortingOptions} onValueChange={setSortBy} value={sortBy} />}
          openOnPress
          openOnHover={false}
          closeOnClickOutside
          side={tooltipSide}
          variants={['menu']}
          visible={sortOpen}
          toggle={(val) => {
            if (val && shouldToggleAuthWall) {
              authWall()()
              return
            }
            toggleSort(val)
          }}
        >
          <Icon
            name='chevrons-up-down'
            variants={['iconSize:3', 'cursor:pointer', 'color:primary3']}
            debugName={'SuccessStoriesFilter'}
          />
        </Tooltip>
      </View>

      <List
        {...listProps}
        separators
        placeholder={{
          ...listProps?.placeholder,
          renderEmpty: Placeholder,
        }}
        rowItemsSpacing={4}
        variants={['hiddenSeparator']}
        renderItem={renderItem}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  wrapper: {
    ...theme.presets.fullWidth,
    ...theme.spacing.padding(0),
  },
  innerWrapper: {
    ...theme.presets.fullWidth,
  },
  listInnerWrapper: {
    maxHeight: '650px',
  },
  blurImage: {
    width: '100%',

    aspectRatio: 1400 / 1384,
  },
  blurButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
  },
}), true)
