import './services/firebaseApp'

import { StyleProvider, ReactQuery, I18NProvider, useEffect, TypeGuards } from '@codeleap/common'
import { Settings, variantProvider, React, I18N, LocalStorageKeys } from './app'
import {
  AppStatusOverlay,
  DebugModal,
  GlobalStyle,
  NoReviewQuestionsModal,
  OrganisationInfo,
  ReviewOrganisation,
  ReviewOrgSuccessModal,
  ShareModal,
  UnknownOrganisation,
  CongratulationsStories,
  variants,
  AchievementDetail,
  AchievementModal,
  PlayerYoutube,
  OnboardingQuestions,
  SignInNewsFeed,
  HereWeGoModal,
} from './components'
import { Provider } from 'react-redux'
import { AppStatus, Pulse, store, useAppSelector } from './redux'
import { APIClient, analytics } from './services'
import CreateStory from './components/Modals/CreateStory'
import StoryDetails from './components/Modals/StoryDetail'
import { Location, useLocation } from '@reach/router'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { toggleOnboardingQuestionsVisibility, LocalStorage, useHandleOpen, useUtm } from './utils'
import { globalHistory } from '@reach/router'

export const Overlays = () => (
  // This is needed for useLocation to work inside the modals
  <Location>
    {() => <>
      <DebugModal />
      <OrganisationInfo />
      <ReviewOrganisation />
      <UnknownOrganisation />
      <CreateStory />
      <NoReviewQuestionsModal />
      <ReviewOrgSuccessModal />
      <StoryDetails />
      <ShareModal />
      <OnboardingQuestions />
      <SignInNewsFeed />
      <CongratulationsStories />
      <AchievementDetail />
      <AchievementModal />
      <PlayerYoutube.Fullscreen />
      <HereWeGoModal />
      <AppStatusOverlay />
    </>
    }
  </Location>
)

const App = ({ children }) => {
  const response = APIClient.Session.useSession(true)
  useHandleOpen({
    mapping: {
      openQuestions: undefined,
      questions: undefined,
      openCreateStory: 'createStory',
    },
    onOpen: () => Pulse.toggleModal('start_page', true),
  })

  const { onboardingQuestionsWasAppeared } = useAppSelector((s) => s.Session)
  const { pathname } = useLocation()

  useEffect(() => {
    const storage = JSON.parse(LocalStorage.getItem(LocalStorageKeys.ONBOARDING_QUESTIONS))
    const profileAnswers = response.profile?.climate_actionability && response.profile?.climate_priority
    const routesToAvoid = ['/profile/settings/']

    if (
      TypeGuards.isNull(storage) &&
      !profileAnswers &&
      response.loginResolved &&
      !onboardingQuestionsWasAppeared &&
      !routesToAvoid.includes(pathname)
    ) {
      AppStatus.setModal(['onboardingQuestions', true])
      toggleOnboardingQuestionsVisibility()
    }
  }, [response?.profile, response.loginResolved, pathname])

  //@ts-ignore
  useUtm(response.profile)

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (response.isLoggedIn && action === 'PUSH') {
        analytics?.track('logged_in_pageview')
      }
    })
  }, [globalHistory])

  return (
    <>
      <GlobalStyle />
      {children}

      <Overlays />
    </>
  )
}

export const Root = ({ children }) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <ReactQueryDevtools initialIsOpen={false} position='right' />
      <Provider store={store}>
        <StyleProvider
          settings={Settings}
          variantProvider={variantProvider}
          variants={variants}
          logger={logger}
        >
          <I18NProvider i18n={I18N}>
            <Location>
              {() => <>
                <App>
                  {children}
                </App>

              </>}
            </Location>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
