import { Act } from './Act'
import { ScoreReviews } from './Reviews'
import { IndustryScoreOverview } from './ScoreOverview'
import { SeeFullList } from './SeeList'
import { ScoresContext } from './context'
import { IndustrySelector } from './IndustrySelector'
import { ScoreStories } from './Stories'
import { TabController } from './TabController'

export const Scores = {
  Act,
  Reviews: ScoreReviews,
  Stories: ScoreStories,
  IndustryScoreOverview,
  TabController,
  SeeFullList,
  IndustrySelector,
  Context: ScoresContext,
}
