import { verifyAchievementProgress } from '../../components/Achievements/Store'
import { api, React, useAppI18N } from '@/app'
import { onUpdate, PaginationResponse, QueryManager, ReactQuery, TypeGuards, useState, waitFor } from '@codeleap/common'
import { queryClient } from './queryClient'
import { ProfileOptions, useProfileOptions } from './session'
import { Organisation, Story, StoryCategory } from '@/types'
import { getListQueryParams, useFlatlistProps } from '@/utils'
import { analytics } from '../analytics'
import { APIClient } from '..'

const BASE_URL = 'story/'

export async function getstoryCategory() {
  const response = await api.get<StoryCategory[]>(`${BASE_URL + 'options/'}`)

  return response.data
}

export const SORT_BY = {
  likes: 'likes',
  created_datetime: 'created_datetime',
} as const

export type StoriesSortBy = typeof SORT_BY[keyof typeof SORT_BY]

const queryKeys = {
  filterOptions: ['story-filter-options'],
}

export type StoryFilters = {
  search?: string
  organisation?: Organisation['id']
  categories?: (StoryCategory['value'])[]
  sort_by?: StoriesSortBy
  author_department?: string[]
  limit?: number
}

export const storiesManager = new QueryManager({
  itemType: {} as Story,
  name: 'stories',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters: StoryFilters) => {

    const response = await api.get<PaginationResponse<Story>>(BASE_URL, {
      params: {
        limit,
        offset,
        organization: filters?.organisation,
        ...filters,
        categories: getListQueryParams(filters?.categories),
        author_department: getListQueryParams(filters?.author_department),
      },
    })

    return response.data
  },
  createItem: async (data) => {

    const { file, ...rest } = data

    const requestData = {
      data: {
        ...rest,
      },
      file: null,
    }

    if (!!file && !TypeGuards.isString(file)) {
      requestData.file = file
    } else {
      requestData.file = 'null'
    }

    const response = await api.post(BASE_URL, requestData, { multipart: true })

    verifyAchievementProgress(response?.data?.achievement_progress)

    return response.data
  },
  updateItem: async (data) => {
    await waitFor(3000)
    const response = await api.patch<Story>(`${BASE_URL}/${data.id}/`, data)
    if (data.title.includes('Error')) {
      throw new Error('Error updating post')
    }
    return response.data
  },
  deleteItem: async (item) => {

    if (storiesManager.meta.throwErrorForDelete) {
      throw new Error('Error deleting post')
    }

    await api.delete(`${BASE_URL}/${item.id}/`)

    return item
  },
  retrieveItem: async (id) => {
    const response = TypeGuards.isNil(id) ? null : await api.get<Story>(`${BASE_URL}${id}/`)

    return response?.data
  },
  initialMeta: {
    optimisticLike: true,
    throwErrorForDelete: false,
    optimisticFavorite: true,
  },
  actions: {
    async toggleLike(manager, storyId: Story['id']) {

      const story = await manager.getItem(storyId, {
        fetchOnNotFoud: false,
        forceRefetch: false,
      })

      const isLiked = story?.likes?.is_liked

      const updatedStory: Story = {
        ...story,
        likes: {
          is_liked: !story.likes?.is_liked,
          count: isLiked ? story?.likes?.count - 1 : story?.likes?.count + 1,
        },
      }

      manager.setItem(updatedStory)

      try {
        const response = await api.post(`${BASE_URL}${storyId}/toggle_like/`, {})

        verifyAchievementProgress(response?.data?.achievement_progress)

      } catch (err) {
        logger.log({ err })
      }

      await manager.refreshItem(storyId)
      analytics?.track?.('story_like')

    },
  },
})

export function useStoryFilterOptions() {
  const query = ReactQuery.useQuery({
    queryKey: queryKeys.filterOptions,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await api.get(`${BASE_URL}filters/`)
      return response.data
    },
  })

  return query.data
}

type UseStoriesParams = {
  organisationId?: Organisation['id']
}

export const useStories = (params: StoryFilters = {}) => {
  const { t } = useAppI18N()
  const { isLoggedIn } = APIClient.Session.useSession()

  const [filter, setFilter] = React.useState<StoryFilters>(() => ({
    search: '',
    sort_by: SORT_BY.created_datetime,
    categories: [],
    author_department: [],

    limit: isLoggedIn ? 10 : 5, // For some reason limit key is not working outside of the filter
    ...params,
  }))

  const stories = storiesManager.use({ filter })

  const filterOptions = useStoryFilterOptions()

  const storiesListProps = useFlatlistProps(stories, {
    noMoreItemsText: t('learn.stories.noMoreStories'),
    disableFetching: !isLoggedIn,
  })

  onUpdate(() => {
    if (isLoggedIn) {
      setFilter(prev => ({ ...prev, limit: 10 }))
      stories?.refresh()
    }
  }, [isLoggedIn])

  const handleToggleLike = (storyId) => {
    stories?.actions?.toggleLike(storyId)
  }

  return {
    stories,
    storiesListProps,
    handleToggleLike,
    filter,
    setFilter,
    filterOptions,
  }
}

export type UseStoriesReturn = ReturnType<typeof useStories>
